import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { connect } from "react-redux";
import { useEffect } from "react";
import { handleSpringDataRequest } from "../../../api";
import { useState } from "react";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import TableSkeleton from "./TableSkeleton";

function ExportReportTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { id: "duration", label: "Duration covered", flex: 1 },
    { id: "reportType", label: "Report name", align: "right", flex: 1 },
    { id: "createdAt", label: "Request Time", align: "right", flex: 1 },
    { id: "status", label: "Status", align: "right", flex: 1 },
    { id: "url", label: "Download", align: "right", flex: 1 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderCellContent = (columnId, row) => {
    if (columnId === "url") {
      if (row?.status === "COMPLETED") {
        return (
          <IconButton onClick={() => window.open(row[columnId], "_blank")}>
            <DownloadIcon />
          </IconButton>
        );
      } else {
        return <RemoveIcon />;
      }
    }
    if (columnId === "duration") {
      return `${moment(row.fromDate).format("Do MMM, YYYY")} - ${moment(
        row.toDate
      ).format("Do MMM, YYYY")}`;
    }
    if (columnId === "createdAt") {
      return moment(row[columnId]).format("Do MMM, YYYY hh:mm A");
    }
    return row[columnId];
  };

  useEffect(() => {
    setLoading(true);
    handleSpringDataRequest(`core/api/v1/reports/get-scheduled-reports`)
      .then((data) => {
        setRows(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <TableSkeleton />
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ height: "83vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        // sx={{ borderBottom: "1px solid #ddd" }}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {renderCellContent(column.id, row)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}

export default connect(mapStateToProps)(ExportReportTable);
