import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { toast } from "material-react-toastify";
import moment from "moment";
import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  ButtonGroup,
  Card,
  ClickAwayListener,
  InputAdornment,
  Rating,
  Switch,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import * as AiIcons from "react-icons/ai";
import { connect } from "react-redux";
import { getCalendarData } from "./InventoryCalendar/api/InventoryApi";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../api/index";
import * as auth from "../modules/Auth/_redux/authRedux";
import { getUserWizardRender } from "../pages/AccessControl/apis/AccessControlApi";
import DashboardMockLoader from "../Utils/DashboardMockLoader";
import { dashboardActions, filtersForDashboard } from "../Utils/Utils";
import DeniedAccess from "./AccessControl/Views/DeniedAccess";
import InformationCard from "./DashboardHelper/InformationCard.js";
import noBooking from "./Folios/ViewManagePayments/not_found.svg";
import "./Tags.scss";
import { Stack, Typography, Button } from "@mui/material";
import { OutlinedButton } from "../Utils/ButtonHelper";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import DashboardReport from "./DashboardHelper/DashboardReport";
import { getAllImagesAndUrl } from "./BookingDashboard/apis/BrandingModalApi";
import SelfCheckinCard from "./DashboardHelper/SelfCheckinCard";
import {
  AMPLITUDE_KEY,
  ENV,
  baseUrl,
} from "./CalendarPage/constants/AmplitudeConstants";
import amplitude from "amplitude-js";
import Currencies from "../Utils/Currencies.json";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import ReferalModal from "../../_metronic/layout/components/extras/dropdowns/support/ReferalModal";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { getCsmDetails } from "../../_metronic/layout/components/extras/dropdowns/apis/NotificationAPI";
import { Link, useHistory } from "react-router-dom";
import PropertyStats from "./GroupDashboard/Stats/PropertyStats";
import { id } from "date-fns/locale";
import EventIcon from "@mui/icons-material/Event";
import { DateRange, DefinedRange } from "react-date-range";
import Freeze from "../Utils/Freeze";
import { Modal } from "react-bootstrap";
import FlexiGPTModal from "./DashboardHelper/FlexiGPTModal";
import { useTranslation } from "react-i18next";
import i18n from "../modules/Auth/pages/i18n";
import RMDashboard from "./GroupDashboard/Stats/RMDashboard.js";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import { RAZORPAY_CLIENT_ID } from "../api/config.js";
import AccountsDashBoard from "./AccountsAndBilling/AccountsDashBoard.jsx";
import SupportModal from "../../_metronic/layout/components/extras/dropdowns/support/SupportModal.js";
import { BiSupport } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Lottie from "react-lottie";
import successAnimationData from "../pages/CalendarPage/Views/lotties/celebration.json";
import OTPInput from "./GroupDashboard/Stats/OTPInput.js";
import PaymentModal from "./AccessControl/PaymentModal.js";

const DashboardCard = React.lazy(() =>
  import("./DashboardHelper/DashboardCard.js")
);

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

function DashboardPage(props) {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  //Amplitude check on dashboard
  const amplitudeEventProperties = {
    hotel_id: props.data?.selectedHotel?.hotelId,
    environment: ENV,
    email: props.data?.email,
    role: props.data?.selectedHotel?.accessControl?.ADMINRIGHTS,
  };

  // setting hotel currency into the local storage
  localStorage.setItem(
    "hotelCurrency",
    props.data?.selectedHotel?.accessControl?.hotel?.hotel_currency
  );

  const email = localStorage.getItem("rootEmail");
  const [loader, setloader] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (email !== null) {
      handleSpringDataRequest(`user/check-master-user?email=${email}`)
        .then((res) => localStorage.setItem("masterUser", res))
        .catch((err) => console.error("Please try again later."));
    } else {
      localStorage.setItem("masterUser", false);
    }
  }, []);

  /* useEffect(() => {
    amplitude
      .getInstance()
      .init(AMPLITUDE_KEY, props.data?.selectedHotel?.hotelId, {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true,
      });
    amplitude
      .getInstance()
      .identify(
        new amplitude.Identify()
          .set("hotel_id", props.data?.selectedHotel?.hotelId)
          .set("email", props.data?.selectedHotel?.accessControl?.email)
          .set("role", props.data?.selectedHotel?.accessControl?.ADMINRIGHTS)
      );
    amplitude
      .getInstance()
      .logEvent("PROPERTY DASHBOARD PAGE LOADED", amplitudeEventProperties);
  }, []); */

  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();

      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      } else {
        if (props?.data?.selectedHotel?.hotelId === "27368") {
          const resp =
            sessionStorage.getItem("rmDash") === "true" ||
            sessionStorage.getItem("rmDash") === true
              ? true
              : false;
          sessionStorage.setItem("rmDash", resp);
          console.log("resp", resp, props?.data?.selectedHotel?.hotelId);
        } else if (
          localStorage.getItem("groupEmail") !== "mhohotels@gmail.com"
        ) {
          sessionStorage.setItem("rmDash", false);
          setDashboardView("dashboard");
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    userBrandingSatus();
    getGoogleReview();
  }, [sessionStorage.getItem("rmDash"), sessionStorage.getItem("groupEmail")]);

  //Address state
  const [address, setAddress] = useState({
    address1: props?.data?.selectedHotel?.accessControl?.hotel?.hotel_address,
    address2:
      props?.data?.selectedHotel?.accessControl?.hotel?.hotel_address_line2,
    city: props?.data?.selectedHotel?.accessControl?.hotel?.hotel_city,
    state: props?.data?.selectedHotel?.accessControl?.hotel?.hotel_state,
    country: props?.data?.selectedHotel?.accessControl?.hotel?.hotel_country,
    pincode: props?.data?.selectedHotel?.accessControl?.hotel?.hotel_zipcode,
  });
  // calling the api
  const [currentDate, setCurrentDate] = useState(new Date());
  const [remountCount, setRemountCount] = useState(0);
  const [isGroup, setisGroup] = useState(false);
  const [googleReviewStatus, setgoogleReviewStatus] = useState(false);

  const refresh = () => setRemountCount(remountCount + 1);
  var formattedDate = moment(currentDate).format("ddd MMM DD YYYY");
  const envType = process.env.REACT_APP_ENV;
  const [selfCheckins, setselfCheckins] = useState([]);
  const [selectedFilter, setselectedFilter] = useState("booking_id");
  const [promotionsData, setpromotionsData] = useState([]);
  const [directModal, setdirectModal] = useState(false);
  const [flexModal, setflexModal] = useState(false);
  const [ratePlan, setratePlan] = useState([]);
  const [genericLoader, setgenericLoader] = useState(false);
  const [showDirectPlanData, setshowDirectPlanData] = useState(false);
  const [paymnetData, setpaymnetData] = useState({});
  const [minRoomValue, setminRoomValue] = useState(0);
  const [showNotif, setshowNotif] = useState(true);
  const [showRazorpay, setshowRazorpay] = useState("PENDING");
  const [notifData, setnotifData] = useState([]);

  const [showAll, setShowAll] = useState(false);

  const toggleShowMore = () => {
    setShowAll(!showAll);
  };

  const openDirectModal = () => setdirectModal(true);
  const closeDirectModal = () => setdirectModal(false);

  const openFlexModal = () => setflexModal(true);
  const closeFlexModal = () => setflexModal(false);

  const getSelfChekinDatas = () => {
    let startDate = moment(currentDate).format("DD-MM-YYYY");
    let endDate = moment(
      moment(currentDate).add(1, "d").format("DD-MM-YYYY")
    )._i;

    handleSpringDataRequest(
      `core/api/v1/reservation/get-selfservice-details/?startDate=${startDate}&endDate=${endDate}`
    )
      .then((res) => setselfCheckins(res.details))
      .catch((err) =>
        toast.error(
          `${t("Error in getting selfCheckins")}. ${t(
            "Please try again later"
          )}.`
        )
      );
  };
  useEffect(() => {
    getSelfChekinDatas();
  }, [currentDate]);

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("groupEmail") !== null &&
  //     localStorage.getItem("groupEmail") !== undefined &&
  //     localStorage.getItem("groupEmail") !== "" &&
  //     localStorage.getItem("groupEmail") !== "null"
  //   ) {
  //     handleSpringDataRequest(
  //       `core/api/v1/reports/check-if-group-reports-enabled?emailId=${localStorage.getItem(
  //         "groupEmail"
  //       )}`
  //     )
  //       .then((res) => setisGroup(res))
  //       .catch((Err) => console.error(Err));
  //   }
  // }, [localStorage.getItem("groupEmail")]);

  //auth redux

  const [groupEmail, setGroupEmail] = useState("");

  useEffect(() => {
    if (!props.data.selectedHotel.accessControl) {
      getAllData();
    } else {
      setGroupEmail(
        props?.data?.selectedHotel?.accessControl?.hotel?.hotel_email
      );

      sessionStorage.setItem(
        "zipcode",
        props?.data?.selectedHotel?.accessControl?.hotel?.hotel_zipcode
      );
      sessionStorage.setItem(
        "showDash",
        JSON.parse(
          props?.data?.selectedHotel?.accessControl?.user_feature_map?.Dashboard
        ).read
      );
    }
  }, []);

  // const gettingFolioConfig = () => {
  //   handleSpringDataRequest(`core/api/v1/folio/get-folio-config`)
  //     .then((data) => props.SetFolioConfig(data))
  //     .catch((err) => console.log(err));
  // };
  // useEffect(() => {
  //   gettingFolioConfig();
  // }, []);

  const getAllData = async () => {
    setloader(true);
    try {
      const hotel = Object.assign({}, props.data.selectedHotel);
      handleSpringDataRequest(
        `core/api/v1/accesscontrol/addUserWizardRender?email=${props.data.email}`
      )
        .then((res) => {
          sessionStorage.setItem("zipcode", res?.hotel?.hotel_zipcode);

          if (res.ADMINRIGHTS !== "ADMIN" || res.ADMINRIGHTS !== "SUBADMIN") {
            sessionStorage.setItem(
              "rmDash",
              JSON.parse(res.user_feature_map?.PropertyStatistics).read ===
                "YES"
            );
          } else {
            sessionStorage.setItem("rmDash", true);
          }
          localStorage.setItem("email", props.data.email);
          sessionStorage.setItem(
            "showDash",
            JSON.parse(res.user_feature_map?.Dashboard).read
          );

          hotel.accessControl = res;
          const allHotels = [...props.data.hotels];
          allHotels.forEach((element) => {
            if (element.hotelId === hotel.hotelId) {
              element.accessControl = res;
            }
          });
          props.selectHotel(hotel);
          setGroupEmail(hotel?.accessControl?.hotel?.hotel_email);
          localStorage.setItem(
            "hotel_tnc",
            hotel?.accessControl?.hotel?.hotel_tnc
          );
          props.setAllHotels(allHotels);
          setAddress({
            address1: hotel?.accessControl?.hotel?.hotel_address,
            address2: hotel?.accessControl?.hotel?.hotel_address_line2,
            city: hotel?.accessControl?.hotel?.hotel_city,
            state: hotel?.accessControl?.hotel?.hotel_state,
            country: hotel?.accessControl?.hotel?.hotel_country,
            pincode: hotel?.accessControl?.hotel?.hotel_zipcode,
          });
          setloader(false);
          userBrandingSatus();
        })
        .catch((err) => {
          console.error(err);
          setloader(false);
        });

      // const data = await getUserWizardRender(
      //   props.data.selectedHotel.hotelId,
      //   props.data.email
      // );
    } catch (e) {
      console.error(e);
    }
  };
  // filtering based on the click
  const [selected, setSelected] = useState("NEW_BOOKINGS");

  // Handling dashboard cards
  const [pointerEvents, setPointerEvents] = useState("auto");

  // Reset properties of the cards to default
  const resetProps = () => {
    setTimeout(() => {
      setPointerEvents("auto");
    }, 500);
  };

  // selected dashboard list
  const handleActiveStatus = (item) => {
    // setResultedData(null);
    setLoadInfo(true);
    setHeading(item === "self_check_in" ? item : item.heading);
    setPages(25);
    setSelected(item === "self_check_in" ? item : item.action);
    setTags([]);
    setShowResults(false);
    refresh();
    setSearchTerm("");
    // setPointerEvents("none");
    // resetProps();
  };

  // calling in the dashboard data
  const [dashData, setDashData] = useState(null);
  function getDashboardData() {
    setDashData(null);
    djangoHandleDataRequests(
      `reports/generateDashDataLite/?date=${formattedDate}&is_today=${moment(
        formattedDate
      )
        .isSame(moment(moment().toDate()).format("ddd MMM DD YYYY"))
        .toString()}`
    )
      .then((res) => {
        setDashData(res);
      })
      .catch((err) => toast.error(`${t("Sorry cannot fetch the data")}`));
    // setloader(false);
  }

  useEffect(() => {
    getDashboardData();
  }, [currentDate]);

  // Getting the property type
  const handleGetPropertyType = () => {
    handleSpringDataRequest("core/api/v1/folio/get-property-type").then((res) =>
      props.setHotelType(res ? res : "STAYFLEXI")
    );
  };
  useEffect(() => {
    if (!props.data.selectedHotel.accessControl) {
      handleGetPropertyType();
    }
  }, []);

  // Getting if the property is xytron enabled or not
  const handleGetXytronEnabled = () => {
    handleSpringDataRequest("core/api/v1/xytron/is-connected").then((res) => {
      sessionStorage.setItem(
        `xytronEnabled${props.data.selectedHotel.hotelId}`,
        res ? res : false
      );
      props.setXytronEnabled(res ? res : false);
    });
  };

  useEffect(() => {
    if (!props.data.selectedHotel.accessControl) {
      handleGetXytronEnabled();
    }
  }, []);

  // Pagination for dashboard data
  const [pages, setPages] = useState(25);

  const paginationComponent = () => (
    <FormControl
      sx={{
        width: "100px",
      }}
      size="small"
    >
      <InputLabel id="demo-simple-select-label">{`${t(
        "Select pages"
      )}`}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={pages}
        label={`${t("Select pages")}`}
        onChange={(e) => setPages(e.target.value)}
      >
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={75}>75</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={125}>125</MenuItem>
        <MenuItem value={150}>150</MenuItem>
        <MenuItem
          value={dashData[selected] && Number(dashData[selected].length)}
        >
          {`${t("All")}`}
        </MenuItem>
      </Select>
    </FormControl>
  );

  // greeting for the tiem
  const [greeting, setGreeting] = useState(`${t("Good Afternoon")}`);

  var now_date = new Date();
  var hours = now_date.getHours();
  useEffect(() => {
    if (hours < 12) {
      setGreeting(`${t("Good morning")}`);
    } else if (hours < 18) {
      setGreeting(`${t("Good afternoon")}`);
    } else {
      setGreeting(`${t("Good evening")}`);
    }
  });

  // changing the state for the accordian

  const [expanded, setExpanded] = useState(false);

  const handleTChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // tags
  const [ShowResults, setShowResults] = useState(false);
  const [tags, setTags] = useState([]);

  const TagsInput = (props) => {
    const removeTags = (indexToRemove) => {
      setTags([...tags.filter((_, index) => index !== indexToRemove)]);
      const tagstemp = tags.filter((_, index) => index !== indexToRemove);

      if (tags.length == 3) {
        let processedData = dashData[selected]
          ?.filter((val) => filtersForDashboard(val, tagstemp[0]))
          .filter((val) => filtersForDashboard(val, tagstemp[1]));
        setResultedData(processedData.slice(0, pages));
        setLoadInfo(false);
      }
      if (tags.length == 2) {
        let processedData = dashData[selected]?.filter((val) =>
          filtersForDashboard(val, tagstemp[0])
        );
        setResultedData(processedData.slice(0, pages));
        setLoadInfo(false);
      }
      if (tags.length === 1) {
        setResultedData(dashData[selected].slice(0, pages));
        setLoadInfo(false);
      }
    };

    const addTags = (event) => {
      if (event.target.value !== "") {
        setTags([...tags, event.target.value]);
        props.selectedTags([...tags, event.target.value]);
        event.target.value = "";
        if (tags.length >= 0) {
          setShowResults(true);
        }
        setResultedData([...resultedData]);
        setSearchTerm("");
        setLoadInfo(false);
      }
    };

    return (
      <div className="tags-input">
        <ul id="tags">
          {tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <span
                className="tag-close-icon"
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
        </ul>
        <input
          type="search"
          value={searchTerm}
          onChange={(e) => setSearch(e.target.value)}
          // autoFocus
          key="random1"
          ref={inputRef}
          autoFocus={inputRef.current === document.activeElement}
          onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
          placeholder={`${t("Type and press enter to add tags and search")}`}
        />
      </div>
    );
  };
  const inputRef = useRef(null);
  const setSearch = (e) => {
    setSearchTerm(e);
    items(e);
  };

  const selectedTags = (tags) => {
    console.log();
  };

  // function to render the data based on the tags
  const [resultedData, setResultedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadInfo, setLoadInfo] = useState(false);

  const items = (e) => {
    if (tags.length === 0) {
      const data = dashData[selected]?.filter((val) =>
        filtersForDashboard(val, e)
      );
      setResultedData(data.slice(0, pages));
      setLoadInfo(false);
    }
    if (tags.length === 1) {
      const data = dashData[selected]
        ?.filter((val) => filtersForDashboard(val, tags[0]))
        ?.filter((val) => filtersForDashboard(val, e));
      setResultedData(data.slice(0, pages));
      setLoadInfo(false);
    }
    if (tags.length === 2) {
      const data = dashData[selected]
        ?.filter((val) => filtersForDashboard(val, tags[0]))
        ?.filter((val) => filtersForDashboard(val, tags[1]))
        ?.filter((val) => filtersForDashboard(val, e));
      setResultedData(data.slice(0, pages));
      setLoadInfo(false);
    }
  };
  //-------------Filter For Arrival and Departure Only--------------------
  const [departureCount, setDepartureCount] = useState(0);
  const [arrivalCount, setArrivalCount] = useState(0);
  //-------------------------Departure-------------------------------------------------
  const [departure, setDeparture] = useState(
    sessionStorage.getItem("departure") === undefined ||
      sessionStorage.getItem("departure") === null ||
      sessionStorage.getItem("departure") === ""
      ? "all"
      : sessionStorage.getItem("departure")
  );
  //-------------------------Arrival-------------------------------------------------
  const [arrival, setArrival] = useState(
    sessionStorage.getItem("arrival") === undefined ||
      sessionStorage.getItem("arrival") === null ||
      sessionStorage.getItem("arrival") === ""
      ? "all"
      : sessionStorage.getItem("arrival")
  );
  useEffect(() => {
    if (dashData && dashData[selected]) {
      setDepartureCount(dashData?.CHECKOUTS?.length);
      setArrivalCount(dashData?.CHECKINS?.length);
      if (selected === `${t("CHECKINS")}`) {
        if (arrival === "all") {
          setResultedData(dashData[selected]);
          setArrivalCount(dashData[selected].length);
        } else if (arrival === "pending_arrival") {
          const arr = [];
          dashData[selected].map((item) => {
            if (item?.booking_status === "CONFIRMED") {
              arr.push(item);
            }
          });
          setResultedData(arr);
          setArrivalCount(arr.length);
        } else if (arrival === "arrival_in_house") {
          const arr = [];
          dashData[selected].map((item) => {
            if (item?.booking_status === "ADMIN_CONFIRMED") {
              arr.push(item);
            }
          });
          setResultedData(arr);
          setArrivalCount(arr.length);
        }
      }

      //----------------------checkout--------------
      else if (selected === `${t("CHECKOUTS")}`) {
        if (departure === "all") {
          setResultedData(dashData[selected]);
          setDepartureCount(dashData[selected].length);
        } else if (departure === "pending_departure") {
          const arr = [];
          dashData[selected].map((item) => {
            if (item?.pending_departures) {
              arr.push(item);
            }
          });
          setResultedData(arr);
          setDepartureCount(arr.length);
        } else if (departure === "checked_out") {
          const arr = [];
          dashData[selected].map((item) => {
            if (item?.booking_status === "CHECKED_OUT") {
              arr.push(item);
            }
          });
          setResultedData(arr);
          setDepartureCount(arr.length);
        }
      }
    }
  }, [dashData, selected, arrival, departure]);
  //---------------------------------------------

  useEffect(() => {
    if (dashData && dashData[selected]) {
      if (selectedFilter === "booking_id") {
        dashData[selected].sort(function (a, b) {
          return b.booking_id.localeCompare(a.booking_id);
        });
      } else if (selectedFilter === "cin") {
        dashData[selected].sort(
          (a, b) =>
            moment(b.cin, "MMM DD, YYYY h:mm A").unix() -
            moment(a.cin, "MMM DD, YYYY h:mm A").unix()
        );
      } else if (selectedFilter === "cout") {
        dashData[selected].sort(
          (a, b) =>
            moment(b.cout, "MMM DD, YYYY h:mm A").unix() -
            moment(a.cout, "MMM DD, YYYY h:mm A").unix()
        );
      } else if (selectedFilter === "booking_user_name") {
        dashData[selected].sort(function (a, b) {
          return a.booking_user_name
            .toLowerCase()
            .localeCompare(b.booking_user_name.toLowerCase());
        });
      } else if (selectedFilter === "room_ids") {
        dashData[selected].sort(function (a, b) {
          return a.room_ids
            .trim()
            .toLowerCase()
            .localeCompare(b.room_ids.trim().toLowerCase());
        });
      }
      setResultedData(dashData[selected].slice(0, pages));
      setLoadInfo(false);
    }
  }, [dashData, selected, pages, selectedFilter, switchOn]);

  // Handle change the date
  const handleDateChange = (val) => {
    setCurrentDate(val);
  };

  // Sending the heading for the report
  const [heading, setHeading] = useState(`t${"New bookings"}`);
  useEffect(() => {
    dashboardActions(dashData?.house_summary).map((item) => {
      return item.name === selected && setHeading(item.heading);
    });
  }, [dashData]);

  // Getting the hotel logo
  const [base64Img, setBase64Img] = useState(null);
  const handleBase64Img = () => {
    handleSpringDataRequest("core/api/v1/image/get-hotel-base64-logo/")
      .then((res) => setBase64Img(res))
      .catch((err) => toast.error(`${t("Error in fethcing the hotel logo")}!`));
  };

  const [currency, setCurrency] = useState("");

  useEffect(() => {
    handleBase64Img();
    let currencySymbol = "";
    const res = Currencies?.map((item) => {
      if (
        item.code ===
        props?.data?.selectedHotel?.accessControl?.hotel?.hotel_currency
      ) {
        currencySymbol = item.symbol;
      }
    });
    setCurrency(currencySymbol);
  }, [props?.data?.selectedHotel?.accessControl?.hotel?.hotel_currency]);

  const [currentEpoch, setcurrentEpoch] = useState(0);
  useEffect(() => {
    handleSpringDataRequest("web-api/get-today-hotel-epoch")
      .then((res) => setcurrentEpoch(res))
      .catch((err) => console.log(err));
  }, []);

  // Condition to render checkin to the customer

  const [checkinHours, setCheckinHours] = useState(9);
  const handleCheckinHours = () =>
    handleSpringDataRequest("core/api/v1/pc/get-checkin-hours")
      .then((res) => setCheckinHours(Number(res.hours_before_checkin)))
      .catch((err) =>
        toast.error(`${t("Error in fetching the checkin hours")}`)
      );

  useEffect(() => {
    handleCheckinHours();
    getOtpVerifiedStatus();
  }, []);

  function sortFactory(prop) {
    return function (a, b) {
      return a[prop].localeCompare(b[prop]);
    };
  }

  useEffect(() => {}, [selectedFilter, dashData]);
  const [inventoryData, setinventoryData] = useState([]);
  useEffect(async () => {
    const data = await getCalendarData(
      {
        from: moment(currentDate).format("DD-MM-YYYY"),
        to: moment(currentDate).format("DD-MM-YYYY"),
      },
      history
    );
    setinventoryData(data);
  }, [currentDate]);

  const [totalRoomsCOunt, settotalRoomsCOunt] = useState(0);

  useEffect(() => {
    let total = 0;
    const data = inventoryData?.allRoomTypes?.map(
      (room) =>
        (total =
          total +
          Number(room?.availableRoomCount[0]?.count) +
          Number(room?.offlineRoomCount[0]?.count))
    );
    settotalRoomsCOunt(total);
  }, [inventoryData]);

  const [referalModal, setreferalModal] = useState(false);
  const openReferalModal = () => {
    setreferalModal(true);
  };

  /* Closes the Support Modal */
  const hideReferalModal = () => {
    setreferalModal(false);
  };

  //CSM DETAILS for Support Modal
  const [csmData, setCsmData] = useState(null);
  useEffect(async () => {
    const groupId = props?.data?.selectedHotel?.accessControl?.hotel?.group_id;

    if (groupId) {
      await getCsmDetails(groupId).then((resp) => {
        if (String(resp?.status) === "200") {
          const data = resp?.data;
          setCsmData(data);
        }
      });
    }
  }, [props?.data?.selectedHotel?.accessControl?.hotel?.group_id]);
  //----------------------Switching between same day cancelation to all cancelation----------
  const [switchOn, setSwitchOn] = useState(true);

  useEffect(() => {
    if (selected === `${t("CANCELLED")}` && dashData) {
      if (switchOn) {
        setResultedData(dashData["TODAY_CANCELLED"]);
      } else {
        setResultedData(dashData["CANCELLED"]);
      }
    }

    //   if (selected === "CANCELLED" && switchOn && dashData) {
    //     setResultedData(dashData["TODAY_CANCELLED"]);
    //   } else if (selected === "CANCELLED" && dashData) {
    //     setResultedData(dashData["CANCELLED"]);
    //   }
  }, [
    switchOn,
    dashData,
    selected,
    pages,
    selectedFilter,
    searchTerm,
    currentDate,
  ]);

  //   return minPrice === Infinity ? 0 : minPrice;
  // };
  //----------------Dashboard , stats-----------------------

  const [dashboardView, setDashboardView] = useState("dashboard");
  //------------------------Date Range Picker ----------------
  const lastSeven = new Date();
  lastSeven.setDate(lastSeven.getDate() - 7);
  const fastSeven = new Date();
  fastSeven.setDate(fastSeven.getDate() + 7);

  const [dateRange, setDateRange] = useState([
    {
      startDate: lastSeven,
      endDate: fastSeven,
      key: "selection",
    },
  ]);

  const styles = {
    position: "absolute",
    top: 28,
    right: 0,
    left: 10,
    zIndex: 100,
    p: 1,
    width: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => {
    setOpen(false);
  };

  const [statsRefresh, setStatsRefresh] = useState();
  //-------------------Account Freeze---------------------

  const [freezed, setFreezed] = useState(sessionStorage.getItem("freezed"));
  useEffect(() => {
    setFreezed(sessionStorage.getItem("freezed"));
  }, [sessionStorage.getItem("freezed")]);

  const getMinimumprice = (room) => {
    let minPrice = Infinity;

    room.ratePlans.forEach((item) => {
      const occupancyPricing = item?.rateInfoMap?.[0]?.occupancyPricing;
      if (occupancyPricing) {
        const minValue = Math.min(...Object.values(occupancyPricing));
        minPrice = Math.min(minPrice, minValue);
      }
    });

    return minPrice === Infinity ? 0 : minPrice;
  };

  // Sort rooms by base price in ascending order
  const sortedRooms = inventoryData?.allRoomTypes
    ?.slice()
    .sort((roomA, roomB) => {
      const basePriceA = getMinimumprice(roomA);
      const basePriceB = getMinimumprice(roomB);
      return basePriceA - basePriceB;
    });

  const displayedRooms = showAll ? sortedRooms : sortedRooms?.slice(0, 5);

  useEffect(() => {
    djangoHandleDataRequests(`promotion/getAllPromotions?active_only=true`)
      .then((res) => setpromotionsData(res))
      .catch((err) => console.error(err));

    djangoHandleDataRequests(`rate/getAllRatePlans`)
      .then((res) => setratePlan(res))
      .catch((err) => console.error(err));
    getData();
  }, []);

  const logEventForWidget = (eventData) => {
    /* amplitude
      .getInstance()
      .init(AMPLITUDE_KEY, props.data?.selectedHotel?.hotelId, {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true,
      });
    amplitude
      .getInstance()
      .identify(
        new amplitude.Identify()
          .set("hotel_id", props.data?.selectedHotel?.hotelId)
          .set("email", props.data?.selectedHotel?.accessControl?.email)
          .set("role", props.data?.selectedHotel?.accessControl?.ADMINRIGHTS)
      );
    amplitude.getInstance().logEvent(eventData, amplitudeEventProperties); */
  };

  let eventMap = new Map();
  eventMap.set(
    "productVideo",
    "DASHBOARD WIDGET - USER CLICKED ON PRODUCT VIDEOS LINK"
  );
  eventMap.set(
    "demoSession",
    "DASHBOARD WIDGET - USER CLICKED ON ATTEND DEMO SESSION"
  );
  eventMap.set(
    "requestDemo",
    "DASHBOARD WIDGET - USER CLICKED ON REQUEST DEMO LINK"
  );
  eventMap.set(
    "downloadAppAppStore",
    "DASHBOARD WIDGET - USER CLICKED ON DOWNLOAD APP VIA APP STORE LINK"
  );
  eventMap.set(
    "downloadAppPlayStore",
    "DASHBOARD WIDGET - USER CLICKED ON DOWNLOAD APP VIA GOOGLE PLAY STORE LINK"
  );
  eventMap.set(
    "enableFlexCollect",
    "DASHBOARD WIDGET - USER CLICKED ON ENABLE FLEX COLLECT LINK"
  );
  eventMap.set(
    "directBookings",
    "DASHBOARD WIDGET - USER CLICKED ON GET MORE DIRECT BOOKINGS"
  );

  const getDateText = (start, end) => {
    let val = "";
    val = val + moment(start).format("MMMM D, YYYY") + " - ";
    val = val + moment(end).format("MMMM D, YYYY");
    return val;
  };

  const createPromotion = () => {
    const data = ratePlan.filter((item) => item.name === "Standard Plan");
    const payload = {
      hotel_id: props.data.selectedHotel.hotelId,
      min_stay_length: 1,
      max_stay_length: 4,
      min_rooms: 1,
      max_rooms: 4,
      promotion_name: "BAR PRICING FOR GHA",
      rate_difference: "7",
      applicable_days: ["1", "6", "5", "4", "2", "3", "0"],
      promoRange: getDateText(moment(), moment().add(2, "years")),
      apply_online: "OFF",
      rate_plan: [data[0].id],
      id: null,
    };
    setgenericLoader(true);
    djangoHandleDataMutationRequest("POST", `promotion/addPromotion/`, payload)
      .then((res) => {
        toast.success("Sucessfully created promotion");
        setgenericLoader(false);
        setshowDirectPlanData(true);
      })
      .catch((err) => {
        console.error("Something went wrong");
        setgenericLoader(false);
      });
  };

  const getData = () => {
    handleSpringDataRequest(`core/api/v1/payment/get-payment-configuration`)
      .then((res) => {
        setpaymnetData(res);
      })
      .catch((err) => console.error(err));
  };

  const enableFlexCollect = () => {
    setgenericLoader(true);
    const payload = {
      hotelId: props.data.selectedHotel.hotelId,
      channels: "booking",
      autoCharge: "YES",
      checkCancellationPolicy: "NO",
      enquiryExpHrs: 24,
      expType: "from_current_time",
      notifEmailList: "",
      notifPhoneList: "",
      changeToEnquiry: "YES",
    };
    if (Object.entries(paymnetData).length === 0) {
      handleSpringDataMutationRequest(
        "POST",
        `core/api/v1/payment/create`,
        payload
      )
        .then((res) => {
          toast.success("Successfully created");
          openFlexModal();
          getData();
          setgenericLoader(false);
        })
        .catch((err) => {
          toast.error("Error in creating");
          setgenericLoader(false);
        });
    } else {
      handleSpringDataMutationRequest(
        "PUT",
        `core/api/v1/payment/update`,
        payload
      )
        .then((res) => {
          toast.success("Successfully updated");
          openFlexModal();
          getData();
          setgenericLoader(false);
        })
        .catch((err) => {
          toast.error("Error in updating");
          setgenericLoader(false);
        });
    }
  };

  const getGoogleReview = () => {
    handleSpringDataRequest(`core/api/v1/google-review/get`)
      .then((res) => {
        setgoogleReviewStatus(res);
      })
      .catch((err) => toast.error("Error in fetching the review"));
  };

  const hotelId = props.data.selectedHotel.hotelId;

  const handleRazorpay = () => {
    const client_id = RAZORPAY_CLIENT_ID;
    const redirect_uri = `${baseUrl?.replace(
      "/api/v2",
      ""
    )}core/api/v1/payments/razorpay/razorpay-submerchant-creation-callback`;
    // console.log("redirect_uri", redirect_uri);
    const state = "8AB2CB117EAE6B26B35E4595F22CF";
    const finalUrl = `https://auth.razorpay.com/authorize?response_type=code&client_id=${client_id}&redirect_uri=${encodeURIComponent(
      redirect_uri
    )}&scope=read_write&state=${hotelId}`;
    window.open(finalUrl, "_blank");
  };

  const checkMerchantOnboardingStatus = () => {
    handleSpringDataRequest(
      `core/api/v1/payment-service/get-razorpay-merchant-onboarding-status`
    )
      .then((data) => {
        setshowRazorpay(data);
        props.SetRazorpayMerchantStatus(data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    checkMerchantOnboardingStatus();
  }, []);

  useEffect(() => {
    const resp = displayedRooms?.map((room) =>
      room?.ratePlans?.filter(
        (ratePlan) => ratePlan?.ratePlanName?.toLowerCase() === "standard plan"
      )?.length > 0
        ? room?.ratePlans?.filter(
            (ratePlan) =>
              ratePlan?.ratePlanName?.toLowerCase() === "standard plan"
          )[0]?.rateInfoMap[0]?.occupancyPricing[1]
        : getMinimumprice(room)
    );

    if (resp?.length > 0) {
      let minValue = resp[0]; // Initialize minValue with the first element of the array

      for (let i = 1; i < resp.length; i++) {
        if (resp[i] < minValue) {
          minValue = resp[i]; // Update minValue if a smaller element is found
        }
      }

      setminRoomValue(minValue);
    }
  }, [displayedRooms]);

  const downloadPdfForRM = () => {
    window.open(
      `${process.env.REACT_APP_SPRING_API_URL}web-api/download-daily-intellegence-report/${props?.data?.selectedHotel?.hotelId}`,
      "_blank"
    );
  };

  // RM DASHBOARD APIS

  const [leadTime, setleadTime] = useState(0);
  const [markettrend, setmarkettrend] = useState([]);
  const [roomPickupBySources, setroomPickupBySources] = useState([]);
  const [revenuePickupBySources, setrevenuePickupBySources] = useState([]);
  const [todayPickup, settodayPickup] = useState(0);
  const [yesterDayPickup, setyesterDayPickup] = useState(0);
  const [todayOccupancy, settodayOccupancy] = useState(0);
  const [yesterDayOccupancy, setyesterDayOccupancy] = useState(0);
  const [todayRevenue, settodayRevenue] = useState(0);
  const [yesterdayRevenue, setyesterdayRevenue] = useState(0);
  const [revenueByRoomTypes, setrevenueByRoomTypes] = useState([]);
  const [revenueBySources, setrevenueBySources] = useState([]);
  const [rateShopperData, setrateShopperData] = useState([]);
  const [rateFinalData, setrateFinalData] = useState([]);
  const [ratesAvg, setratesAvg] = useState(0);

  const getLeadTime = () => {
    handleSpringDataRequest(`core/api/v1/reports/get-stats-lead-time`)
      .then((res) => setleadTime(res))
      .catch((err) => toast.error("Something went wrong"));
  };

  const getMarketTrends = () => {
    handleSpringDataRequest(`core/api/v1/reports/get-stats-market-trend`)
      .then((resp) => {
        if (Object.entries(resp).length > 0) {
          const data = Object.entries(resp)
            .map(([date, { occupancy }]) => ({
              name: moment(date, "DD-MM-YYYY"), // Parse date string using Moment.js
              occupancy,
            }))
            .sort((a, b) => a.name - b.name) // Sort by date in descending order
            .map(({ name, occupancy }) => ({
              name: name.format("DD MMM YYYY"), // Format date back to original string format
              occupancy,
            }));

          const topThreeData = data.slice(0, 3);
          setmarkettrend(topThreeData);
        } else {
          setmarkettrend([]);
        }
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  const getPickupTrends = () => {
    handleSpringDataRequest(`core/api/v1/reports/get-stats-pickup-trend`)
      .then((res) => {
        if (Object.entries(res.roomSoldMap).length > 0) {
          const data = Object.entries(res.roomSoldMap).map(
            ([name, roomsSold]) => ({ name, roomsSold })
          );

          setroomPickupBySources(
            data.sort((a, b) => b.roomsSold - a.roomsSold)
          );
        } else {
          setroomPickupBySources([]);
        }

        if (Object.entries(res.revenueMap).length > 0) {
          const resp = Object.entries(res.revenueMap).map(
            ([name, revenue]) => ({
              name,
              revenue,
            })
          );

          setrevenuePickupBySources(resp.sort((a, b) => b.revenue - a.revenue));
        } else {
          setrevenuePickupBySources([]);
        }

        settodayPickup(res.todayPickup);
        setyesterDayPickup(res.yesterdayPickup);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const getRoomRevenue = () => {
    handleSpringDataRequest(`core/api/v1/reports/get-stats-room-revenue`)
      .then((res) => {
        settodayRevenue(res.todayRevenue);
        setyesterdayRevenue(res.yesterdayRevenue);

        if (Object.entries(res.roomTypeMap).length > 0) {
          setrevenueByRoomTypes(
            Object.entries(res.roomTypeMap)
              .map(([name, price]) => ({
                name: name,
                price: price,
              }))
              .sort((a, b) => b.price - a.price)
          );
        } else {
          setrevenueByRoomTypes([]);
        }

        if (Object.entries(res.sourceMap).length > 0) {
          setrevenueBySources(
            Object.entries(res.sourceMap)
              .map(([name, price]) => ({
                name: name,
                price: price,
              }))
              .sort((a, b) => b.price - a.price)
          );
        } else {
          setrevenueBySources([]);
        }
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  const getInventoryData = () => {
    handleSpringDataRequest(`core/api/v1/reports/get-stats-room-inventory`)
      .then((res) => {
        settodayOccupancy(res.todayOccupancy);
        setyesterDayOccupancy(res.yesterdayOccupancy);
      })
      .catch((err) => toast.error("Something went wrong"));
  };

  const getRateShopperData = () => {
    handleSpringDataRequest(`core/api/v1/rate-shopper/get-rate-shopper-report`)
      .then((res) => {
        const newData = res.data.map((item) => {
          return {
            ...item,
            rate1: item.rate1 === null ? 0 : item.rate1,
            // rate2: item.rate2 === null ? 0 : item.rate2,
            // rate3: item.rate3 === null ?0 : item.rate3,
          };
        });

        let sum = 0;
        newData.map((item) => (sum += item.rate1));
        let average = sum / newData?.length;

        setratesAvg(parseFloat(average).toFixed(2));

        const finalData = newData
          .filter((item) => item.channelName === "BOOKING")
          .map((item) => ({
            name: item.chotelName,
            price: item.rate1,
          }));
        setrateShopperData(finalData);
      })
      .catch((err) => toast.error("Error in fetching data"));
  };

  useEffect(() => {
    getLeadTime();
    getMarketTrends();
    getPickupTrends();
    getRoomRevenue();
    getInventoryData();
    getRateShopperData();
    getDfHotelData();
    getCustomNotif();
    getFeedback();
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("rmDash") !== undefined &&
      sessionStorage.getItem("rmDash") !== null
    ) {
      if (sessionStorage.getItem("rmDash") === "true") {
        setDashboardView("rmDashboard");
      } else {
        setDashboardView("dashboard");
      }
    }
  }, [sessionStorage.getItem("rmDash")]);

  const [conversionfactor, setconversionfactor] = useState(0);

  useEffect(() => {
    const curr = props?.data?.selectedHotel?.hotelCurrency;
    handleSpringDataRequest(
      `web-api/currency-convert-value?from=INR&to=${curr}`
    )
      .then((res) => setconversionfactor(res))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("zipcode") !== null &&
      sessionStorage.getItem("zipcode") !== undefined &&
      sessionStorage.getItem("zipcode") !== ""
    ) {
      getAllSevenDaysData();
      getMonthlyTrends();
    }
  }, [sessionStorage.getItem("zipcode")]);

  const [monthlyTrends, setmonthlyTrends] = useState([]);
  const [sevenDaysRate, setsevenDaysRate] = useState([]);
  const getMonthlyTrends = () => {
    const zipcode = sessionStorage.getItem("zipcode");
    handleSpringDataRequest(
      `core/api/v1/cityInsights/get-all?zipcode=${zipcode}`
    )
      .then((res) => {
        const result = [];
        const dattas = res?.map((item) => {
          let obj = {};
          obj["zipcode"] = item.zipcode;
          obj["month"] = item.month;
          obj["insights"] = JSON.parse(item.insights);
          result.push(obj);
        });

        setmonthlyTrends(result);
      })
      .catch((err) => console.error(err));
  };

  const getAllSevenDaysData = () => {
    setloader(true);
    const zipcode = sessionStorage.getItem("zipcode");
    handleSpringDataRequest(
      `core/api/v1/cityDemandTrends/get-all-seven-days-data?zipcode=${zipcode}&todaysDate=${moment().format(
        "DD-MM-YYYY"
      )}`
    )
      .then((res) => {
        const result = [];
        const dattas = res?.map((item) => {
          let obj = {};
          obj["zipcode"] = item.zipcode;
          obj["date"] = moment.unix(item.date).format("MMM DD, YYYY");
          obj["star"] = item.star;
          obj["demand"] = JSON.parse(item.demand);
          obj["epoch"] = item.date;
          result.push(obj);
        });

        setsevenDaysRate(result);
        setloader(false);
      })
      .catch((err) => {
        console.error(err);
        setloader(false);
      });
  };

  const [dfHotels, setdfHotels] = useState([]);
  const getDfHotelData = () => {
    djangoHandleDataRequests(`metaAdmin/getMetaAdminDFDashData/`).then((res) =>
      setdfHotels(res.hotelTableData.map((item) => item.hotel_id))
    );
  };

  const [Accbalance, setAccbalance] = useState(0);
  const getAccountBalance = (groupid) => {
    handleSpringDataRequest(
      `core/api/v1/account-ledger/get-account-balance?groupId=${groupid}&hotelId=${groupid}`
    )
      .then((res) => {
        setAccbalance(Number(res?.message));
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const [showAutoPay, setshowAutoPay] = useState(false);
  const getIndianPaymentMethodDetails = (groupid) => {
    handleSpringDataRequest(
      `core/api/v1/account-payment-method/get-account-payment-method-india?groupId=${groupid}&hotelId=${groupid}`
    )
      .then((res) => {
        if (res && res !== undefined && res !== "FAILED") {
          setshowAutoPay(true);
        }
      })
      .catch((err) => {
        setshowAutoPay(false);
        console.log("Error is : ", err);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("mainGroupId")) {
      getAccountBalance(localStorage.getItem("mainGroupId"));
      getIndianPaymentMethodDetails(localStorage.getItem("mainGroupId"));
    }
  }, [localStorage.getItem("mainGroupId")]);

  useEffect(() => {
    sessionStorage.setItem("arrival", arrival);
    sessionStorage.setItem("departure", departure);
  }, [arrival, departure]);

  useEffect(() => {
    if (
      sessionStorage.getItem("arrival") !== undefined &&
      sessionStorage.getItem("arrival") !== null &&
      sessionStorage.getItem("arrival") !== ""
    ) {
      setArrival(sessionStorage.getItem("arrival"));
      setDeparture(sessionStorage.getItem("departure"));
    }
  }, [sessionStorage.getItem("arrival"), sessionStorage.getItem("departure")]);

  const [support, setSupport] = useState(false);

  /* Opens the Support Modal */
  const openSupport = () => {
    setSupport(true);
  };

  const [phone, setphone] = useState("");
  /* Closes the Support Modal */
  const hideSupport = () => {
    setSupport(false);
  };

  const getCustomNotif = () => {
    handleSpringDataRequest(`core/api/v1/customNotif/get`)
      .then((res) => setnotifData(res))
      .catch((err) => console.error(err));
  };

  const [showFeedback, setshowFeedback] = useState(false);
  const [feedbackRating, setfeedbackRating] = useState("");
  const [feedabackPage, setfeedabackPage] = useState(false);
  const [feedback, setfeedback] = useState("");
  const [feedbackLoader, setfeedbackLoader] = useState(false);
  const getFeedback = () => {
    handleSpringDataRequest(`core/api/v1/feeback/get`).then((res) => {
      if (res.length >= 1) {
        setshowFeedback(false);
      } else {
        setshowFeedback(true);
      }
    });
  };

  const submitFeedback = () => {
    if (feedback.trim()?.length <= 0) {
      return toast.error("Please enter feedback");
    }
    setfeedbackLoader(true);
    handleSpringDataMutationRequest("POST", `web-api/send-feedback-to-csm`, {
      hotelId: props?.data?.selectedHotel?.hotelId,
      hotelName: props?.data?.selectedHotel?.hotelName,
      rating: feedbackRating,
      feedback: feedback,
      csmName:
        csmData !== undefined &&
        csmData !== null &&
        Object.entries(csmData).length > 0 &&
        csmData?.account_csm_name !== undefined &&
        csmData?.account_csm_name !== null
          ? csmData?.account_csm_name
          : "",
    })
      .then((res) => {
        toast.success("Successfully submitted feedback");
        getFeedback();
        setfeedbackLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setfeedbackLoader(false);
      });
  };
  const [otpLoader, setotpLoader] = useState(false);
  const [showVerify, setshowVerify] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [showOtpSuccess, setshowOtpSuccess] = useState(false);
  const [isOtpVerified, setisOtpVerified] = useState(false);

  const getOtpVerifiedStatus = () => {
    handleSpringDataRequest(`core/api/v1/hotelVerification/get`).then((res) => {
      if (res.length > 0 && res[0].verified === true) {
        setisOtpVerified(true);
      } else {
        setisOtpVerified(false);
      }
    });
  };

  const sendOtp = () => {
    setotpLoader(true);
    handleSpringDataRequest(
      `core/api/v1/hotelVerification/send-otp?mobileNumber=%2B${phone}`
    )
      .then((res) => {
        toast.success("OTP has been successfully sent to the contact number");
        setotpLoader(false);
        setshowVerify(true);
      })
      .catch((err) => {
        toast.error("Error in sending otp retry again");
        setotpLoader(false);
      });
  };

  const verifyOtp = () => {
    setotpLoader(true);
    handleSpringDataRequest(
      `core/api/v1/hotelVerification/verify-otp?mobileNumber=%2B${phone}&otp=${otp.join(
        ""
      )}`
    )
      .then((res) => {
        setotpLoader(false);
        setshowOtpSuccess(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setotpLoader(false);
      });
  };

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [daysDiff, setdaysDiff] = useState(0);

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };
  const [paymentAmount, setpaymentAmount] = useState(0);
  const [finalDate, setfinalDate] = useState(new Date());
  const [showPaymentBanner, setshowPaymentBanner] = useState(false);
  const [showPaymentNotifBanner, setshowPaymentNotifBanner] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("mainGroupId") &&
      localStorage.getItem("mainGroupId") !== undefined &&
      localStorage.getItem("mainGroupId") !== null
    ) {
      handleSpringDataRequest(
        `core/api/v1/account/get-single/?groupId=${localStorage.getItem(
          "mainGroupId"
        )}`
      )
        .then((res) => {
          if (
            res?.account_details?.payment_dues_notification_banner === "YES"
          ) {
            setshowPaymentNotifBanner(true);
          } else {
            setshowPaymentNotifBanner(false);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [localStorage.getItem("mainGroupId")]);

  useEffect(() => {
    if (
      localStorage.getItem("mainGroupId") &&
      localStorage.getItem("mainGroupId") !== undefined &&
      localStorage.getItem("mainGroupId") !== null &&
      showPaymentNotifBanner
    ) {
      handleSpringDataRequest(
        `web-api/get-payment-dues-amount?groupId=${localStorage.getItem(
          "mainGroupId"
        )}`
      ).then((res) => {
        if (
          res.pendingAmount < 0 &&
          Number(res.daysDiff) >= 15 &&
          Number(res.daysDiff) < 30
        ) {
          setpaymentAmount(res.pendingAmount);
          setshowPaymentBanner(true);
          setdaysDiff(Number(res.daysDiff));
          setfinalDate(res.date);
        } else if (
          res.pendingAmount < 0 &&
          Number(res.daysDiff) >= 30 &&
          Number(res.daysDiff) <= 60
        ) {
          setpaymentAmount(res.pendingAmount);
          setShowPaymentModal(true);
          setdaysDiff(Number(res.daysDiff));
          setfinalDate(res.date);
        }
      });
    }
  }, [localStorage.getItem("mainGroupId"), showPaymentNotifBanner]);

  return loader === false ? (
    <div>
      {sessionStorage.getItem("showDash") !== "YES" ? (
        props.hotelType !== "ISHA" && <DeniedAccess />
      ) : dashData ? (
        dashData ? (
          <div className="d-flex" style={{ width: "100%" }}>
            <div className="dashboardWrapper">
              <div className="filterWrapper w-100">
                <div className="calendar w-100">
                  <div className="greeting w-100">
                    {greeting}
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <div className="msg w-100 d-flex align-items-center  justify-content-between">
                        <Box
                          sx={{
                            width: "80%",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <p className="mb-0 font-weight-bold mr-2">
                            {dashboardView === "stats" &&
                              `${t("Here")}, ${t(
                                "you can assess your property performance through a variety of statistics"
                              )}.${t("Select dates")}`}
                            {dashboardView === "dashboard" &&
                              `${t(
                                "Here is what going on with your property on"
                              )}`}
                            {dashboardView === "rmDashboard" &&
                              `${t(
                                "Welcome, here is your property performance"
                              )}`}
                          </p>

                          {dashboardView === "dashboard" && (
                            <LocalizationProvider
                              sx={{
                                fontWeight: "bold !important",
                                padding: "0px",
                                margin: "0px",
                              }}
                              dateAdapter={AdapterDateFns}
                            >
                              <DesktopDatePicker
                                inputFormat="dd MMM yyyy"
                                sx={{
                                  fontWeight: "bold !important",
                                  padding: "0px",
                                  margin: "0px",
                                }}
                                value={currentDate}
                                onChange={(newValue) => {
                                  handleDateChange(newValue);
                                }}
                                className="customizeDatePicker"
                                renderInput={(params) => (
                                  <TextField
                                    sx={{
                                      width: "110px",
                                      fontWeight: "bold !important",
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                    className="customizeDatePicker"
                                    variant="standard"
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                          {dashboardView === "stats" && (
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <Box sx={{ position: "relative" }}>
                                <p
                                  className="mb-0 font-weight-bold mr-2"
                                  style={{
                                    textDecoration: "underline",
                                    font: "inherit",
                                  }}
                                >
                                  {moment(dateRange[0].startDate).format(
                                    "DD MMM YYYY"
                                  )}{" "}
                                  {" to "}
                                  {moment(dateRange[0].endDate).format(
                                    "DD MMM YYYY"
                                  )}
                                  <span
                                  // style={{ marginLeft: 4, cursor: "pointer" }}
                                  >
                                    <EventIcon
                                      sx={{ marginLeft: 1, cursor: "pointer" }}
                                      onClick={handleClick}
                                    />
                                  </span>
                                </p>

                                {/* <Button
                                variant="outlined"
                                onClick={handleClick}
                                fullWidth
                              >
                                
                                {moment(dateRange[0].startDate).format(
                                  "DD-MM-YY"
                                )}{" "}
                                {" to "}
                                {moment(dateRange[0].endDate).format(
                                  "DD-MM-YY"
                                )}
                              </Button> */}
                                {open ? (
                                  <Box sx={styles}>
                                    <Stack spacing={3}>
                                      <Stack
                                        className="position-relative w-100 d-flex justify-content-between"
                                        direction="row"
                                        sx={{ justifyContent: "space-between" }}
                                      >
                                        <DefinedRange
                                          onChange={(item) =>
                                            setDateRange([item.selection])
                                          }
                                          ranges={dateRange}
                                        />
                                        <DateRange
                                          editableDateInputs={true}
                                          onChange={(item) =>
                                            setDateRange([item.selection])
                                          }
                                          moveRangeOnFirstSelection={false}
                                          ranges={dateRange}
                                        />
                                      </Stack>

                                      <Stack
                                        className="position-relative w-100 d-flex justify-content-between"
                                        direction="row"
                                        sx={{ justifyContent: "space-between" }}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={() => setOpen(false)}
                                          color="error"
                                        >
                                          {`${t("Cancel")}`}
                                        </Button>
                                        <Button
                                          variant="custom-button"
                                          onClick={(item) => {
                                            setStatsRefresh(new Date());
                                            handleClickAway();
                                          }}
                                        >
                                          {`${t("Apply")}`}
                                        </Button>
                                      </Stack>
                                    </Stack>
                                  </Box>
                                ) : null}
                              </Box>
                            </ClickAwayListener>
                          )}
                        </Box>
                        <Box sx={{ ml: 5 }} style={{ display: "flex" }}>
                          <ButtonGroup
                            sx={{
                              overflow: "auto",
                              paddingLeft: 2,
                              paddingRight: 3,
                            }}
                            orientation={matches ? "horizontal" : "vertical"}
                            size={matches ? "medium" : "small"}
                          >
                            <Button
                              sx={{
                                textTransform: "none",
                                fontWeight: "bold",
                                fontSize: "1rem",
                              }}
                              // variant={
                              //   dashboardView === "dashboard"
                              //     ? "custom-button"
                              //     : "outlined"
                              // }

                              style={{
                                backgroundColor: `${
                                  dashboardView === "dashboard"
                                    ? "#36454F"
                                    : "white"
                                }`,
                                color: `${
                                  dashboardView === "dashboard"
                                    ? "white"
                                    : "#36454F"
                                }`,
                                borderColor: "#36454F",
                              }}
                              onClick={() => {
                                sessionStorage.setItem("rmDash", false);
                                setDashboardView("dashboard");
                              }}
                            >
                              {`${t("Reservations")}`}
                            </Button>
                            {/* {JSON.parse(
                              props?.data?.selectedHotel?.accessControl
                                ?.user_feature_map?.PropertyStatistics
                            ).read === "YES" && (
                              <Button
                                sx={{ textTransform: "none" }}
                                variant={
                                  dashboardView === "stats"
                                    ? "custom-button"
                                    : "outlined"
                                }
                                onClick={() => {
                                  setDashboardView("stats");
                                }}
                              >
                                {`${t("Property stats")}`}
                              </Button>
                            )} */}
                            {(JSON.parse(
                              props?.data?.selectedHotel?.accessControl
                                ?.user_feature_map?.PropertyStatistics
                            ).read === "YES" ||
                              props?.data?.selectedHotel?.accessControl
                                ?.ADMINRIGHTS === "ADMIN" ||
                              props?.data?.selectedHotel?.accessControl
                                ?.ADMINRIGHTS === "SUBADMIN") &&
                              String(whiteLabelled) === "DISABLED" &&
                              localStorage.getItem("groupEmail") !==
                                "mhohotels@gmail.com" &&
                              props?.data?.selectedHotel?.hotelId !==
                                "27368" && (
                                <Button
                                  sx={{
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                  }}
                                  style={{
                                    backgroundColor: `${
                                      dashboardView === "rmDashboard"
                                        ? "#36454F"
                                        : "white"
                                    }`,
                                    color: `${
                                      dashboardView === "rmDashboard"
                                        ? "white"
                                        : "#36454F"
                                    }`,
                                    borderColor: "#36454F",
                                  }}
                                  // variant={
                                  //   dashboardView === "rmDashboard"
                                  //     ? "custom-button"
                                  //     : "outlined"
                                  // }
                                  onClick={() => {
                                    sessionStorage.setItem("rmDash", true);
                                    setDashboardView("rmDashboard");
                                  }}
                                >
                                  {`${t("Performance")}`}
                                </Button>
                              )}

                            {(localStorage.getItem("groupEmail") ===
                              "mhohotels@gmail.com" ||
                              props?.data?.selectedHotel?.hotelId ===
                                "27368") && (
                              <Button
                                sx={{
                                  textTransform: "none",
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                }}
                                // variant={
                                //   dashboardView === "rmDashboard"
                                //     ? "custom-button"
                                //     : "outlined"
                                // }
                                style={{
                                  backgroundColor: `${
                                    dashboardView === "rmDashboard"
                                      ? "#36454F"
                                      : "white"
                                  }`,
                                  color: `${
                                    dashboardView === "rmDashboard"
                                      ? "white"
                                      : "#36454F"
                                  }`,
                                  borderColor: "#36454F",
                                }}
                                onClick={() => {
                                  sessionStorage.setItem("rmDash", true);
                                  setDashboardView("rmDashboard");
                                }}
                              >
                                {`${t("Performance")}`}
                              </Button>
                            )}
                          </ButtonGroup>
                          {dashboardView === "rmDashboard" && (
                            <Tooltip title={t("Download pdf")}>
                              <IconButton onClick={downloadPdfForRM}>
                                <AiIcons.AiFillPrinter
                                  size="27.5px"
                                  color="black"
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {/* 
                          <FormControl sx={{ minWidth: 180 }}>
                            <InputLabel id="demo-simple-select-label">
                              {`${t("Switch dashboard")}`}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={dashboardView}
                              label={`${t("Switch dashboard")}`}
                              size="small"
                              onChange={(e) => setDashboardView(e.target.value)}
                            >
                              <MenuItem value={"dashboard"}>
                                {`${t("Property dashboard")}`}
                              </MenuItem>
                              {JSON.parse(
                                props?.data?.selectedHotel?.accessControl
                                  ?.user_feature_map?.PropertyStatistics
                              ).read === "YES" && (
                                <MenuItem value={"stats"}>
                                  {`${t("Property stats")}`}
                                </MenuItem>
                              )}
                              <MenuItem value={"rmDashboard"}>
                                {`${t("RM Dashboard")}`}
                              </MenuItem>
                            </Select>
                          </FormControl> */}
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {dashboardView === "stats" && (
                <PropertyStats
                  date_Range={dateRange}
                  statsRefresh={statsRefresh}
                  currency={currency}
                />
              )}

              {dashboardView === "rmDashboard" && (
                <RMDashboard
                  currency={currency}
                  minRoomValue={minRoomValue}
                  hotelId={props.data.selectedHotel.hotelId}
                  whiteLabelled={whiteLabelled}
                  isGroup={isGroup}
                  leadTime={leadTime}
                  markettrend={markettrend}
                  roomPickupBySources={roomPickupBySources}
                  revenuePickupBySources={revenuePickupBySources}
                  todayPickup={todayPickup}
                  yesterDayPickup={yesterDayPickup}
                  todayOccupancy={todayOccupancy}
                  yesterDayOccupancy={yesterDayOccupancy}
                  todayRevenue={todayRevenue}
                  yesterdayRevenue={yesterdayRevenue}
                  revenueByRoomTypes={revenueByRoomTypes}
                  revenueBySources={revenueBySources}
                  rateShopperData={rateShopperData}
                  rateFinalData={rateFinalData}
                  setrateFinalData={setrateFinalData}
                  ratesAvg={ratesAvg}
                  conversionfactor={conversionfactor}
                  sevenDaysRate={sevenDaysRate}
                  monthlyTrends={monthlyTrends}
                  loader={loader}
                  moreThanOneProp={
                    props?.data?.hotels.filter(
                      (item) => item.status === "ACTIVE"
                    ).length < 2
                  }
                  Accbalance={Accbalance}
                  showAutoPay={showAutoPay}
                  setshowNotif={setshowNotif}
                  csmData={csmData}
                  showNotif={showNotif}
                  hotelType={props.hotelType}
                  country={props?.data?.selectedHotel?.accessControl?.hotel?.hotel_country?.toLowerCase()}
                  notifData={notifData}
                  openSupport={() => openSupport()}
                  showFeedback={showFeedback}
                  setfeedbackRating={setfeedbackRating}
                  feedbackRating={feedbackRating}
                  setfeedabackPage={setfeedabackPage}
                  feedabackPage={feedabackPage}
                  setfeedback={setfeedback}
                  feedback={feedback}
                  submitFeedback={() => submitFeedback()}
                  feedbackLoader={feedbackLoader}
                  setphone={setphone}
                  phone={phone}
                  sendOtp={() => sendOtp()}
                  verifyOtp={() => verifyOtp()}
                  otpLoader={otpLoader}
                  showVerify={showVerify}
                  otp={otp}
                  setOtp={setOtp}
                  showOtpSuccess={showOtpSuccess}
                  isOtpVerified={isOtpVerified}
                  showPaymentModal={showPaymentModal}
                  paymentAmount={paymentAmount}
                  daysDiff={daysDiff}
                  showPaymentBanner={showPaymentBanner}
                  finalDate={finalDate}
                  handleRazorpay={handleRazorpay}
                  showRazorpay={showRazorpay}
                />
              )}

              {dashboardView === "dashboard" && (
                <>
                  {/* {String(whiteLabelled) === "DISABLED" &&
                    props.hotelType !== "ISHA" &&
                    notifData.length > 0 &&
                    notifData[0].status === "ACTIVE" &&
                    (notifData[0].country === "all" ||
                      props?.data?.selectedHotel?.accessControl?.hotel?.hotel_country?.toLowerCase() ===
                        notifData[0].country) && (
                      <div className="w-100">
                        <div
                          className="informationCardWrapperNotif"
                          style={{
                            height: "16rem",
                            marginTop: "2rem",
                            paddingLeft: "2rem",
                            cursor: "default",
                            display: `${showNotif === false && "none"}`,
                          }}
                        >
                          <div className="w-100">
                            <div
                              className="d-flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              {" "}
                              <p
                                className="badge badge-danger mb-0"
                                style={{ marginTop: "1rem" }}
                              >
                                {notifData[0].tag}
                              </p>
                              <AiIcons.AiOutlineClose
                                size="20px"
                                color="black"
                                style={{
                                  float: "right",
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                onClick={() => setshowNotif(false)}
                              />
                            </div>
                          </div>
                          <div
                            className="d-flex w-100"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="w-75">
                              <div
                                style={{
                                  color: "black",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  width: "100%",
                                  textAlign: "left",
                                  marginTop: "1rem",
                                }}
                              >
                                {notifData[0].header}
                              </div>
                              <div
                                style={{
                                  color: "black",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  marginTop: "1rem",
                                  marginBottom: "0.5rem",
                                  width: "100%",
                                  textAlign: "left",
                                }}
                              >
                                {notifData[0].message}
                              </div>
                              <div
                                className="w-100"
                                style={{ marginTop: "1rem" }}
                              >
                                <Button
                                  style={{ float: "left" }}
                                  variant="custom-button"
                                  onClick={() =>
                                    window.open(
                                      `${notifData[0].button_link}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  {notifData[0].button_name}
                                </Button>
                              </div>
                            </div>
                            {notifData[0].image !== "" && (
                              <div className="w-25">
                                <img
                                  style={{
                                    width: "50%",
                                    marginRight: "4rem",
                                    height: "90%",
                                    borderRadius: "10px",
                                  }}
                                  src={notifData[0].image}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )} */}

                  {/* {String(whiteLabelled) === "DISABLED" &&
                    props.hotelType !== "ISHA" &&
                    showFeedback &&
                    props?.data?.selectedHotel?.accessControl?.hotel?.hotel_country?.toLowerCase() ===
                      "india" &&
                    showOtpSuccess &&
                    isOtpVerified === false && (
                      <div className="w-100">
                        <div
                          className="informationCardWrapperNotif"
                          style={{
                            height: "16rem",
                            marginTop: "2rem",
                            paddingLeft: "2rem",
                            cursor: "default",
                            display: `${showNotif === false && "none"}`,
                          }}
                        >
                          <div className="w-100">
                            <div
                              className="d-flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              {" "}
                              <p
                                className="badge badge-danger mb-0"
                                style={{ marginTop: "1rem" }}
                              >
                                Important
                              </p>
                              <AiIcons.AiOutlineClose
                                size="20px"
                                color="black"
                                style={{
                                  float: "right",
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                onClick={() => setshowNotif(false)}
                              />
                            </div>
                          </div>
                          <Lottie
                            options={successAnimation}
                            height={100}
                            width={100}
                          />
                          <div
                            style={{
                              color: "black",
                              fontWeight: "500",
                              fontSize: "18px",
                              fontWeight: "bold",
                              width: "100%",

                              marginBottom: "1rem",
                            }}
                          >
                            {`Thanks your number +${phone} is now verified`}
                          </div>
                        </div>
                      </div>
                    )} */}

                  {/* English webinar banner */}
                  {String(whiteLabelled) === "DISABLED" &&
                    props.hotelType !== "ISHA" &&
                    props?.data?.selectedHotel?.accessControl?.hotel?.hotel_country?.toLowerCase() ===
                      "india" &&
                    showRazorpay !== "ENABLED" && (
                      <div className="w-100">
                        <div
                          className="informationCardWrapperNotif"
                          style={{
                            height: "15rem",
                            marginTop: "2rem",
                            paddingLeft: "2rem",
                            cursor: "default",
                            alignItems: "flex-start",
                            display: `${showNotif === false && "none"}`,
                          }}
                        >
                          <div className="w-100">
                            <div
                              className="d-flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              {" "}
                              <p
                                className="badge badge-danger mb-0"
                                style={{ marginTop: "1rem" }}
                              >
                                {"Important"}
                              </p>
                              <AiIcons.AiOutlineClose
                                size="20px"
                                color="black"
                                style={{
                                  float: "right",
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                onClick={() => setshowNotif(false)}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              color: "black",
                              fontWeight: "500",
                              fontSize: "18px",
                              marginTop: 2,
                              // marginLeft: "1rem",
                            }}
                          >
                            Complete Razorpay onboarding
                          </div>
                          <p
                            className="font-weight-bold mt-2 "
                            style={{
                              color: "gray",
                              textAlign: "left",
                              // marginRight: "1rem",
                            }}
                          >
                            As per RBI guidelines, all merchants should complete
                            the KYC process in order to use Razorpay payment
                            gateway.
                          </p>
                          <div
                            className="font-weight-bold mt-2 "
                            style={{ textAlign: "left" }}
                          >
                            Review{" "}
                            <a
                              href="https://razorpay.com/docs/payments/easy-create-account/"
                              target="_blank"
                            >
                              Razorpay’s
                            </a>{" "}
                            account opening guidelines to get started.
                          </div>
                          <Button
                            variant="outlined"
                            className={"newsupportModalButton"}
                            sx={{ mt: 2 }}
                            onClick={handleRazorpay}
                          >
                            Setup now
                          </Button>
                        </div>
                      </div>
                    )}

                  {/* {String(whiteLabelled) === "DISABLED" &&
                    showPaymentBanner &&
                    props.hotelType !== "ISHA" &&
                    props?.data?.selectedHotel?.accessControl?.hotel?.hotel_country?.toLowerCase() ===
                      "india" && (
                      <div className="w-100">
                        <div
                          className="informationCardWrapperNotif"
                          style={{
                            height: "16rem",
                            marginTop: "2rem",
                            paddingLeft: "2rem",
                            cursor: "default",
                            display: `${showNotif === false && "none"}`,
                          }}
                        >
                          <div className="w-100">
                            <div
                              className="d-flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              {" "}
                              <p
                                className="badge badge-danger mb-0"
                                style={{ marginTop: "1rem" }}
                              >
                                {"Important payment reminder"}
                              </p>
                              <AiIcons.AiOutlineClose
                                size="20px"
                                color="black"
                                style={{
                                  float: "right",
                                  marginRight: "1rem",
                                  marginTop: "1rem",
                                }}
                                onClick={() => setshowNotif(false)}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              color: "black",
                              fontWeight: "500",
                              fontSize: "18px",
                              fontWeight: "bold",
                              width: "100%",
                              textAlign: "left",
                              marginTop: "2rem",
                            }}
                          >
                            Your have
                            <strong>{` INR ${Math.abs(paymentAmount)}`}</strong>{" "}
                            is overdue by {`${daysDiff}`} days . Please clear
                            your dues by{" "}
                            {`${moment(finalDate, "YYYY-MM-DD HH:mm:ss")
                              .add(30, "days")
                              .format("D MMMM YYYY")}`}
                            .
                          </div>

                          <div
                            className="d-flex"
                            style={{
                              marginTop: "3rem",
                              marginBottom: "1rem",
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            <Button
                              variant="custom-button"
                              style={{ width: "12%" }}
                              onClick={() =>
                                history.push({
                                  pathname: "recommendations",
                                  search: `?hotelId=${props.data.selectedHotel.hotelId}&showPayment=true&paynow=true&showAutoPay=false`,
                                })
                              }
                            >
                              Pay now
                            </Button>
                          </div>
                        </div>
                      </div>
                    )} */}

                  {groupEmail !== "implementation@skypalaceinn.com" &&
                    groupEmail !== "reservation@maharashtratourism.gov.in" &&
                    props.hotelType !== "ISHA" &&
                    String(whiteLabelled) === "DISABLED" && (
                      <div
                        style={{
                          // marginTop: "1.2rem",
                          marginRight: "1rem",
                          // marginBottom: "1rem",
                        }}
                      >
                        <ReferalModal
                          show={referalModal}
                          hide={hideReferalModal}
                        ></ReferalModal>
                      </div>
                    )}
                  <div className="bookingInfo">
                    {dashboardActions(
                      dashData?.house_summary,
                      switchOn,
                      departureCount,

                      arrivalCount
                    ).map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="infoCard"
                          style={{
                            marginRight: "5px",
                            pointerEvents: pointerEvents,
                          }}
                          onClick={() => {
                            selected !== item.action &&
                              handleActiveStatus(item);
                          }}
                        >
                          {selected === `${item.action}` ? (
                            <InformationCard
                              colorCode={item.color}
                              value={item.value}
                              heading={item.heading}
                              cardWidth="200px"
                              cardHeight="150px"
                              fontSize="75px"
                              marginLeft="0px"
                              transform="scale(1.1)"
                              boxShadow="0px 0px 16px 6px #00000033"
                              selectedLanguage={props.selectedLanguage}
                            />
                          ) : (
                            <InformationCard
                              colorCode={item.color}
                              value={item.value}
                              heading={item.heading}
                              marginLeft="0px"
                              selectedLanguage={props.selectedLanguage}
                            />
                          )}
                        </div>
                      );
                    })}
                    <div
                      className="infoCard"
                      style={{ marginRight: "5px" }}
                      onClick={() => handleActiveStatus(`${"self_check_in"}`)}
                    >
                      {selected === "self_check_in" ? (
                        <InformationCard
                          colorCode={"pink"}
                          value={selfCheckins?.length}
                          heading={`${t("Magic link")}`}
                          marginLeft="0px"
                          cardWidth="200px"
                          cardHeight="150px"
                          fontSize="75px"
                          transform="scale(1.1)"
                        />
                      ) : (
                        <InformationCard
                          colorCode={"pink"}
                          value={selfCheckins?.length}
                          heading={`${t("Magic link")}`}
                          marginLeft="0px"
                        />
                      )}
                    </div>
                  </div>

                  <Stack
                    direction="row"
                    className="position-relative d-flex justify-content-between"
                    spacing={2}
                  >
                    <Stack>
                      {selected !== "self_check_in" &&
                        dashData[selected] &&
                        dashData[selected]?.length > 0 && (
                          <div className="dashboardFiltering d-flex align-items-center">
                            <TagsInput selectedTags={selectedTags} tags={[]} />
                            {ShowResults && (
                              <h4 className="mb-0 ml-3">{`${
                                resultedData.length > 0
                                  ? resultedData.length +
                                    ` ${t("results found")}`
                                  : ""
                              } `}</h4>
                            )}
                          </div>
                        )}
                    </Stack>

                    <Stack
                      direction="row"
                      spacing={1}
                      style={{ marginTop: "10px" }}
                    >
                      {selected === "CHECKINS" && (
                        <FormControl fullWidth style={{ width: "8rem" }}>
                          <InputLabel id="demo-simple-select-label">
                            {`${t("Arrival")}`}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="ArrivalType"
                            size="small"
                            name="ArrivalType"
                            onChange={(e) => setArrival(e.target.value)}
                            label={`${t("Arrival")}`}
                            style={{ width: "8rem" }}
                            value={arrival}
                          >
                            <MenuItem value={"all"}> {`${t("All")}`}</MenuItem>
                            <MenuItem value={"pending_arrival"}>
                              {`${t("Pending Arrival")}`}
                            </MenuItem>
                            <MenuItem value={"arrival_in_house"}>
                              {`${t("Arrival In House")}`}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      {selected === "CHECKOUTS" && (
                        <FormControl fullWidth style={{ width: "8rem" }}>
                          <InputLabel id="demo-simple-select-label">
                            {`${t("Departure")}`}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="DepartureType"
                            size="small"
                            name="DepartureType"
                            onChange={(e) => setDeparture(e.target.value)}
                            label={`${t("Departure")}`}
                            style={{ width: "8rem" }}
                            value={departure}
                          >
                            <MenuItem value={"all"}> {`${t("ALL")}`}</MenuItem>
                            <MenuItem value={"pending_departure"}>
                              {`${t("Pending Departure")}`}
                            </MenuItem>
                            <MenuItem value={"checked_out"}>
                              {`${t("Checked")}-${t("out")}`}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      {selected === "CANCELLED" && (
                        <FormControl fullWidth style={{ width: "8rem" }}>
                          <InputLabel id="demo-simple-select-label">
                            {`${t("Cancellations")}`}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="CancellationsType"
                            size="small"
                            name="CancellationsType"
                            onChange={(e) => setSwitchOn(e.target.value)}
                            label={`${t("Cancellations")}`}
                            style={{ width: "8rem" }}
                            value={switchOn}
                          >
                            <MenuItem value={true}>
                              {`${t("Cancelled today")}`}
                            </MenuItem>
                            <MenuItem value={false}>
                              {`${t("Cancelled for today")}`}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}

                      {dashData[selected]?.length > 0 && (
                        <FormControl fullWidth style={{ width: "8rem" }}>
                          <InputLabel id="demo-simple-select-label">
                            {`${t("Sort by")}`}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="promoCodeType"
                            size="small"
                            name="promoCodeType"
                            onChange={(e) => setselectedFilter(e.target.value)}
                            label={`${t("Sort by")}`}
                            style={{ width: "8rem" }}
                            value={selectedFilter}
                          >
                            <MenuItem value={"booking_user_name"}>
                              {`${t("Name")}`}
                            </MenuItem>
                            <MenuItem value={"cin"}>
                              {" "}
                              {`${t("Check")}-${t("in")}`}
                            </MenuItem>
                            <MenuItem value={"cout"}>
                              {`${t("Check")}-${t("out")}`}
                            </MenuItem>
                            <MenuItem value={"booking_made_on"}>
                              {`${t("Booking made on")}`}
                            </MenuItem>
                            <MenuItem value={"booking_id"}>
                              {`${t("Booking id")}`}
                            </MenuItem>
                            <MenuItem value={"room_ids"}>
                              {`${t("Room no")}.`}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      {selected !== "self_check_in" &&
                        dashData[selected] &&
                        dashData[selected].length > 25 &&
                        paginationComponent()}

                      {selected !== "self_check_in" &&
                        resultedData &&
                        dashData[selected]?.length > 0 &&
                        resultedData?.length > 0 && (
                          <PDFDownloadLink
                            document={
                              <Document title={`${t("Dashboard report")}`}>
                                <Page
                                  size="A4"
                                  orientation="landscape"
                                  style={styles.page}
                                  wrap
                                >
                                  <DashboardReport
                                    dashboardData={resultedData}
                                    heading={heading}
                                    hotelName={
                                      props.data.selectedHotel.hotelName
                                    }
                                    hotelLogo={base64Img}
                                    address={address}
                                    currency={currency}
                                  />
                                </Page>
                              </Document>
                            }
                            fileName={`${selected}.pdf`}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? (
                                <p style={{ fontSize: "0.8rem" }}>
                                  <OutlinedButton text="Preparing report..." />
                                </p>
                              ) : (
                                <a
                                  href={url}
                                  style={{ fontSize: "0.8rem", width: "9rem" }}
                                  rel={"noreferrer"}
                                  target="_blank"
                                >
                                  {window.screen.width > 416 && (
                                    <OutlinedButton
                                      text={`${t("Download report")}`}
                                    />
                                  )}
                                </a>
                              )
                            }
                          </PDFDownloadLink>
                        )}
                    </Stack>
                  </Stack>
                  {selected === "CANCELLED" && (
                    <Stack
                      direction="row"
                      className="position-relative d-flex justify-content-between"
                      spacing={2}
                    >
                      <Stack></Stack>
                      <Stack>
                        <h4 className="mb-0 mt-3">
                          {switchOn && dashData?.TODAY_CANCELLED?.length > 0
                            ? dashData?.TODAY_CANCELLED?.length +
                              ` ${t("results found")}`
                            : ""}
                          {!switchOn && dashData?.CANCELLED?.length > 0
                            ? dashData?.CANCELLED?.length +
                              ` ${t("results found")}`
                            : ""}
                        </h4>
                      </Stack>
                    </Stack>
                  )}

                  {selected === "CHECKINS" && (
                    <Stack
                      direction="row"
                      className="position-relative d-flex justify-content-between"
                      spacing={2}
                    >
                      <Stack></Stack>
                      <Stack>
                        <h4 className="mb-0 mt-3">{`${
                          arrivalCount > 0
                            ? arrivalCount + ` ${t("results found")}`
                            : ""
                        } `}</h4>
                      </Stack>
                    </Stack>
                  )}
                  {selected === "CHECKOUTS" && (
                    <Stack
                      direction="row"
                      className="position-relative d-flex justify-content-between"
                      spacing={2}
                    >
                      <Stack></Stack>
                      <Stack>
                        <h4 className="mb-0 mt-3">{`${
                          departureCount > 0
                            ? departureCount + ` ${t("results found")}`
                            : ""
                        } `}</h4>
                      </Stack>
                    </Stack>
                  )}

                  {selected !== "self_check_in" &&
                    loadInfo === false &&
                    (resultedData ? (
                      <div style={{ marginTop: "45px" }}>
                        {dashData[selected]?.length > 0 ||
                        resultedData?.length > 0 ? (
                          <Stack>
                            {resultedData?.map(
                              (item, idx) =>
                                (item.is_enquiry !== "true" ||
                                  item.reservation_status !== "CANCELLED") && (
                                  <DashboardCard
                                    item={item}
                                    idx={idx}
                                    toggleChange={() => handleTChange(idx)}
                                    expanded={expanded}
                                    getDashboardData={getDashboardData}
                                    hotelId={props.data.selectedHotel.hotelId}
                                    currentEpoch={currentEpoch}
                                    checkinHours={checkinHours}
                                    switchOn={switchOn}
                                    googleReviewStatus={googleReviewStatus}
                                    selected={selected}
                                    whiteLabelled={whiteLabelled}
                                  />
                                )
                            )}
                            {dashData[selected] &&
                              dashData[selected].length > 25 && (
                                <Stack className="mt-8 position-relative w-100 justify-content-end">
                                  {paginationComponent()}
                                </Stack>
                              )}
                          </Stack>
                        ) : (
                          <div>
                            {selected !== "self_check_in" && (
                              <div
                                className="d-flex flex-column align-items-center"
                                style={{ marginTop: "0rem" }}
                              >
                                <img
                                  style={{ width: "35%" }}
                                  src={noBooking}
                                  alt=""
                                />
                                <p className="mt-4">
                                  {`${t(
                                    "There are no bookings for this category"
                                  )}.`}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <Stack
                        style={{ marginTop: "45px" }}
                        className="position-relative justify-content-center align-items-center"
                        direction="row"
                        spacing={2}
                      >
                        <Typography> {`${t("Loading")}`}</Typography>
                        <CircularProgress size="25px" />
                      </Stack>
                    ))}

                  {
                    <div style={{ marginTop: "45px" }}>
                      {selected === "self_check_in" && selfCheckins?.length > 0
                        ? selfCheckins?.map((item, idx) => (
                            <SelfCheckinCard
                              item={item}
                              idx={idx}
                              toggleChange={() => handleTChange(idx)}
                              expanded={expanded}
                              getDashboardData={getSelfChekinDatas}
                              hotelId={props.data.selectedHotel.hotelId}
                            />
                          ))
                        : selected === "self_check_in" && (
                            <div
                              className="d-flex flex-column align-items-center"
                              style={{ marginTop: "0rem" }}
                            >
                              <img
                                style={{ width: "35%" }}
                                src={noBooking}
                                alt=""
                              />
                              <p className="mt-4">
                                {`${t(
                                  "There are no bookings for this category"
                                )}.`}
                              </p>
                            </div>
                          )}
                    </div>
                  }
                </>
              )}
            </div>
            {(dashboardView === "dashboard" ||
              dashboardView === "rmDashboard") && (
              <>
                <div style={{ width: "25%", marginTop: "25px" }}>
                  <div className="d-flex flex-column">
                    <div>
                      <img
                        style={{ marginBottom: "1rem", float: "right" }}
                        src={
                          "https://prop-images.s3.us-east-2.amazonaws.com/misc/pottedPlant.png"
                        }
                      />
                    </div>

                    <div
                      className="informationCardWrapper"
                      style={{ height: "auto", width: "auto", padding: "1rem" }}
                    >
                      <div
                        style={{
                          color: "black",
                          fontWeight: "500",
                          fontSize: "18px",
                          marginLeft: "1rem",
                        }}
                      >
                        {`${t("Availability Today")}`}
                      </div>
                      <div className="w-100 mt-2">
                        <div className="d-flex justify-content-between  w-100">
                          <p
                            className="font-weight-bold"
                            style={{
                              color: "gray",
                              width: "50%",
                              textAlign: "start",
                            }}
                          >
                            {`${t("Room category")}`}
                          </p>
                          <p
                            className="font-weight-bold"
                            style={{
                              color: "gray",
                              width: "25%",
                              textAlign: "center",
                            }}
                          >
                            {`${t("Inventory")}`}
                          </p>
                          <p
                            className="font-weight-bold"
                            style={{
                              color: "gray",
                              width: "25%",
                              textAlign: "end",
                            }}
                          >
                            {`${t("Base Price")}`}
                          </p>
                        </div>

                        {displayedRooms?.map((room) => (
                          <div
                            className="d-flex justify-content-between  w-100"
                            key={room.roomTypeName}
                          >
                            <p
                              className="font-weight-bold"
                              style={{
                                color: "gray",
                                width: "60%",
                                textAlign: "start",
                              }}
                            >
                              {room?.roomTypeName}
                            </p>
                            <p
                              className="font-weight-bold"
                              style={{
                                color: "gray",
                                width: "10%",
                                textAlign: "center",
                              }}
                            >
                              {room?.availableRoomCount[0]?.count +
                                room?.offlineRoomCount[0]?.count}
                            </p>
                            <p
                              className="font-weight-bold"
                              style={{
                                color: "gray",
                                width: "30%",
                                textAlign: "end",
                              }}
                            >
                              {currency}{" "}
                              {room?.ratePlans?.filter(
                                (ratePlan) =>
                                  ratePlan?.ratePlanName?.toLowerCase() ===
                                  "standard plan"
                              )?.length > 0
                                ? room?.ratePlans?.filter(
                                    (ratePlan) =>
                                      ratePlan?.ratePlanName?.toLowerCase() ===
                                      "standard plan"
                                  )[0]?.rateInfoMap[0]?.occupancyPricing[1]
                                : getMinimumprice(room)}
                            </p>
                          </div>
                        ))}

                        {showAll && (
                          <div className="d-flex justify-content-between ">
                            <p
                              style={{
                                color: "gray",
                                width: "62%",
                                textAlign: "start",
                                fontWeight: "bold",
                              }}
                            >
                              {`${t("Total Available Rooms")}`}
                            </p>
                            <p
                              className="font-weight-bold"
                              style={{
                                color: "gray",
                                width: "10%",
                                textAlign: "start",
                                fontWeight: "bold",
                              }}
                            >
                              {totalRoomsCOunt}
                            </p>
                            <p
                              className="font-weight-bold"
                              style={{
                                color: "gray",
                                width: "25%",
                                textAlign: "end",
                              }}
                            ></p>
                          </div>
                        )}

                        {inventoryData?.allRoomTypes?.length > 5 && (
                          <div className="ml-4 mr-4">
                            <button
                              onClick={toggleShowMore}
                              className="btn btn-link"
                              style={{
                                color: "#1BC5BD",
                                textDecoration: "underline",
                                fontWeight: "bold",
                              }}
                            >
                              {showAll
                                ? `${t("Show less")}`
                                : `${t("Show More")}`}
                            </button>
                          </div>
                        )}
                      </div>
                      {/* <div className="d-flex justify-content-between ml-4 mr-4 w-100">
                        <p
                          className="font-weight-bold"
                          style={{
                            color: "gray",
                            width: "50%",
                            textAlign: "start",
                          }}
                        >
                          Room category
                        </p>
                        <p
                          className="font-weight-bold"
                          style={{
                            color: "gray",
                            width: "25%",
                            textAlign: "center",
                          }}
                        >
                          Inventory
                        </p>
                        <p
                          className="font-weight-bold"
                          style={{
                            color: "gray",
                            width: "25%",
                            textAlign: "end",
                          }}
                        >
                          Base Price
                        </p>
                      </div>

                      {inventoryData &&
                        inventoryData?.allRoomTypes?.length > 0 &&
                        inventoryData?.allRoomTypes?.map((room) => (
                          <div className="d-flex justify-content-between ml-4 mr-4 w-100">
                            <p
                              className="font-weight-bold"
                              style={{
                                color: "gray",
                                width: "60%",
                                textAlign: "start",
                              }}
                            >
                              {room?.roomTypeName}
                            </p>
                            <p
                              className="font-weight-bold"
                              style={{
                                color: "gray",
                                width: "10%",
                                textAlign: "center",
                              }}
                            >
                              {room?.availableRoomCount[0]?.count +
                                room?.offlineRoomCount[0]?.count}
                            </p>
                            <p
                              className="font-weight-bold"
                              style={{
                                color: "gray",
                                width: "30%",
                                textAlign: "end",
                              }}
                            >
                              {currency}{" "}
                              {
                                room?.ratePlans?.filter(
                                  (ratePlan) =>
                                    ratePlan?.ratePlanName?.toLowerCase() ===
                                    "standard plan"
                                )?.length > 0
                                  ? room?.ratePlans?.filter(
                                      (ratePlan) =>
                                        ratePlan?.ratePlanName?.toLowerCase() ===
                                        "standard plan"
                                    )[0]?.rateInfoMap[0]?.occupancyPricing[1]
                                  : getMinimumprice(room)

                                // room?.ratePlans[0]?.rateInfoMap[0]
                                //     ?.occupancyPricing[1]
                              }
                            </p>
                          </div>
                        ))}

                      <div className="d-flex justify-content-between ml-4 mr-4 w-100">
                        <p
                          style={{
                            color: "gray",
                            width: "62%",
                            textAlign: "start",
                            fontWeight: "bold",
                          }}
                        >
                          Total Available Rooms
                        </p>
                        <p
                          className="font-weight-bold"
                          style={{
                            color: "gray",
                            width: "10%",
                            textAlign: "start",
                            fontWeight: "bold",
                          }}
                        >
                          {totalRoomsCOunt}
                        </p>
                        <p
                          className="font-weight-bold"
                          style={{
                            color: "gray",
                            width: "25%",
                            textAlign: "end",
                          }}
                        ></p>
                      </div> */}
                    </div>
                  </div>
                  {/* {props.hotelType !== "ISHA" && (
                     <div
                       className="informationCardWrapper"
                       style={{
                         height: "auto",
                         width: "auto",
                         marginTop: "1rem",
                       }}
                     >
                       <div
                         style={{
                           color: "black",
                           fontWeight: "500",
                           fontSize: "18px",
                           marginLeft: "1rem",
                         }}
                       >
                         {t("Razorpay")}
                       </div>
                       <p
                         className="font-weight-bold mt-2 ml-4"
                         style={{ color: "gray", textAlign: "left" }}
                       >
                         {t("Razorpay description")}
                       </p>
                       <Button
                         variant="custom-button"
                         onClick={handleRazorpay}
                         style={{ marginBottom: "1rem" }}
                       >
                         Razorpay
                       </Button>
                     </div>
                   )} */}
                  {promotionsData.length <= 0 &&
                    JSON.parse(
                      props?.data?.selectedHotel?.accessControl
                        ?.user_feature_map?.Promotions
                    ).read === "YES" &&
                    String(whiteLabelled) === "DISABLED" && (
                      <div
                        className="informationCardWrapper"
                        style={{
                          height: "auto",
                          width: "auto",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                            marginLeft: "1rem",
                          }}
                        >
                          {`${t("Get")} 20% ${t("more direct bookings")}`}
                        </div>
                        <p
                          className="font-weight-bold mt-2 ml-4"
                          style={{ color: "gray", textAlign: "left" }}
                        >
                          {`${t(
                            "Your direct rates on google is low compared to OTAs"
                          )}. ${t("Click below to enable")} 7% ${t(
                            "discount on direct bookings"
                          )}.`}
                        </p>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            logEventForWidget(
                              eventMap.get("enableFlexCollect")
                            );
                            openDirectModal();
                          }}
                          className={"newsupportModalButton"}
                          style={{ marginBottom: "1rem" }}
                        >
                          {`${t("Get more direct bookings")}`}
                        </Button>
                      </div>
                    )}

                  {/* AUTOPAY */}

                  {String(whiteLabelled) === "DISABLED" &&
                    props.hotelType !== "ISHA" &&
                    props?.data?.selectedHotel?.accessControl?.hotel?.hotel_country?.toLowerCase() ===
                      "india" &&
                    !showAutoPay &&
                    parseFloat(Accbalance) < 0 && (
                      <div
                        className="informationCardWrapper"
                        style={{
                          height: "auto",
                          width: "auto",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            className="badge badge-danger mb-0"
                            style={{
                              marginTop: "1rem",
                              textAlign: "right",
                              marginRight: "1rem",
                            }}
                          >
                            {t("Important")}
                          </p>
                        </div>
                        <div
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "15px",
                            fontWeight: "bold",
                            width: "100%",
                            textAlign: "left",
                            marginTop: "1rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                          }}
                        >
                          <span style={{ color: "#1DC5BD" }}>
                            Limited time offer
                          </span>
                          {`: Setup auto pay and receive 5% discount!`}
                        </div>
                        <div
                          className="font-weight-bold mt-2 ml-4"
                          style={{ textAlign: "left" }}
                        >
                          {csmData !== undefined &&
                          csmData !== null &&
                          Object.entries(csmData).length > 0 &&
                          csmData?.account_csm_name !== undefined &&
                          csmData?.account_csm_name !== null
                            ? `Receive 5% discount on all future Stayflexi invoices. If you have questions, please contact your account manager ${csmData?.account_csm_name} on ${csmData?.account_csm_phone}`
                            : `Receive 5% discount on all future Stayflexi invoices. If you have questions, please contact support@stayflexi.com`}
                        </div>

                        <div
                          className="w-100"
                          style={{ marginTop: "1rem", marginBottom: "1rem" }}
                        >
                          <Button
                            variant="custom-button"
                            onClick={() =>
                              history.push({
                                pathname: "recommendations",
                                search: `?hotelId=${hotelId}&showPayment=true&paynow=${false}&showAutoPay=${true}`,
                              })
                            }
                          >
                            Setup autopay
                          </Button>
                        </div>
                      </div>
                    )}
                  {/* AUTOPAY */}
                  {/* Razorpay */}
                  {/* {String(whiteLabelled) === "DISABLED" &&
                    props.hotelType !== "ISHA" &&
                    props?.data?.selectedHotel?.accessControl?.hotel?.hotel_country?.toLowerCase() ===
                      "india" &&
                    showRazorpay !== "ENABLED" && (
                      <div
                        className="informationCardWrapper"
                        style={{
                          height: "auto",
                          width: "auto",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            className="badge badge-danger mb-0"
                            style={{
                              marginTop: "1rem",
                              textAlign: "right",
                              marginRight: "1rem",
                            }}
                          >
                            {t("Important")}
                          </p>
                        </div>
                        <div
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                            marginLeft: "1rem",
                          }}
                        >
                          Complete Razorpay onboarding
                        </div>
                        <p
                          className="font-weight-bold mt-2 ml-4"
                          style={{
                            color: "gray",
                            textAlign: "left",
                            marginRight: "1rem",
                          }}
                        >
                          As per RBI guidelines, all merchants should complete
                          the KYC process in order to use Razorpay payment
                          gateway.
                        </p>
                        <div
                          className="font-weight-bold mt-2 ml-4"
                          style={{ textAlign: "left" }}
                        >
                          Review{" "}
                          <a
                            href="https://razorpay.com/docs/payments/easy-create-account/"
                            target="_blank"
                          >
                            Razorpay’s
                          </a>{" "}
                          account opening guidelines to get started.
                        </div>
                        <Button
                          variant="outlined"
                          className={"newsupportModalButton"}
                          style={{ marginBottom: "1rem" }}
                          onClick={handleRazorpay}
                        >
                          Setup now
                        </Button>
                      </div>
                    )} */}

                  {JSON.parse(
                    props?.data?.selectedHotel?.accessControl?.user_feature_map
                      ?.PaymentAutomation
                  ).read === "YES" &&
                    String(whiteLabelled) === "DISABLED" &&
                    (Object.entries(paymnetData).length === 0 ||
                      paymnetData.auto_charge === "NO") && (
                      <div
                        className="informationCardWrapper"
                        style={{
                          height: "auto",
                          width: "auto",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                            marginLeft: "1rem",
                          }}
                        >
                          {`${t("Reduce booking")}.${t("com cancellations")}`}
                        </div>
                        <p
                          className="font-weight-bold mt-2 ml-4"
                          style={{ color: "gray", textAlign: "left" }}
                        >
                          {`${t(
                            "Stayflexi has launched an innovative payment automation system"
                          )}. ${t("It holds bookings with invalid cards")}, ${t(
                            "collects payments"
                          )}, ${t(
                            "and confirms bookings upon successful payment"
                          )}.`}
                        </p>
                        <Button
                          variant="outlined"
                          style={{ marginBottom: "1rem" }}
                          className={"newsupportModalButton"}
                          onClick={() => {
                            logEventForWidget(eventMap.get("directBookings"));
                            enableFlexCollect();
                          }}
                        >
                          {`${t("Enable Flex Collect")}`}
                        </Button>
                      </div>
                    )}
                  {/* {groupEmail !== "implementation@skypalaceinn.com" &&
                    groupEmail !== "reservation@maharashtratourism.gov.in" &&
                    String(whiteLabelled) === "DISABLED" && (
                      <div
                        className="informationCardWrapper"
                        style={{
                          height: "auto",
                          width: "auto",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                            marginLeft: "1rem",
                          }}
                        >
                          {`What's ${t("new in stayflexi")}?`}
                        </div>
                        <p
                          className="font-weight-bold mt-2"
                          style={{
                            color: "gray",
                            textAlign: "left",
                            marginLeft: "1rem",
                          }}
                        >
                          {`${t(
                            `Learn all the new features we launched on`
                          )} April 18, 2024`}
                        </p>

                        <div class="video-container">
                          <iframe
                            src="https://www.youtube.com/embed/ZqLwouNwdaU"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                          ></iframe>
                        </div>

                        <div
                          className="d-flex justify-content-between w-100"
                          style={{ marginTop: "2rem", marginBottom: "1rem" }}
                        >
                          <div
                            onClick={() => {
                              logEventForWidget(eventMap.get("productVideo"));
                            }}
                          >
                            <a
                              href="https://www.youtube.com/playlist?list=PLaPcDGXpN0pjuhJPWMBkjFpngOo7-uBO0"
                              to="https://www.youtube.com/playlist?list=PLaPcDGXpN0pjuhJPWMBkjFpngOo7-uBO0"
                              padding={2}
                              target="_blank"
                              style={{
                                textDecoration: "underline",
                                fontWeight: "bold",
                                marginLeft: "1rem",
                              }}
                            >
                              {`${t("Product Videos")}`}
                            </a>
                          </div>
                          <div
                            onClick={() => {
                              logEventForWidget(eventMap.get("demoSession"));
                            }}
                            startIcon={<FeedbackIcon />}
                          >
                            <a
                              // underline="hover"
                              href="https://calendly.com/stayflexi-webinars"
                              to="https://calendly.com/stayflexi-webinars"
                              padding={2}
                              target="_blank"
                              style={{
                                textDecoration: "underline",
                                fontWeight: "bold",
                                marginRight: "1rem",
                              }}
                            >
                              {`${t("Attend a demo session")}`}
                            </a>
                          </div>
                        </div>
                      </div>
                    )} */}
                  {String(whiteLabelled) === "DISABLED" &&
                    !dfHotels.includes(hotelId) &&
                    localStorage.getItem("groupEmail") !==
                      "bookings@revenuefirst.in" &&
                    isGroup !== "OK" && (
                      <div
                        className="informationCardWrapper"
                        style={{
                          height: "auto",
                          width: "auto",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          className="d-flex  flex-column mt-4"
                          onClick={() => {
                            logEventForWidget(eventMap.get("requestDemo"));
                          }}
                        >
                          {" "}
                          <div
                            style={{
                              color: "black",
                              fontWeight: "500",
                              fontSize: "18px",
                              marginLeft: "1rem",
                            }}
                          >
                            {`${t("Upgrade to Stayflexi Empower")}`}
                          </div>
                          <p
                            className="font-weight-bold mt-2 ml-4"
                            style={{ color: "gray" }}
                          >
                            {`${t(
                              "Revenue management service by Stayflexi to boost your online bookings by"
                            )} 30%.`}
                          </p>
                          <div className="justify-content-center w-100">
                            <Button
                              variant="outlined"
                              target="_blank"
                              href="https://business.stayflexi.com/campaign/stayflexi-empower-revenue-management-for-hotels"
                              className={"supportModalButton"}
                              style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                                width: "20rem",
                              }}
                              startIcon={<FeedbackIcon />}
                            >
                              {`${t("Request demo")}`}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  {String(whiteLabelled) === "DISABLED" && (
                    <div
                      className="informationCardWrapper"
                      style={{
                        height: "auto",
                        width: "auto",
                        marginTop: "1rem",
                      }}
                    >
                      <div className="d-flex  flex-column mt-4">
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                            marginLeft: "1rem",
                          }}
                        >
                          {`${t("Download Stayflexi App")}`}
                        </div>
                        <p
                          className="font-weight-bold mt-2 "
                          style={{
                            color: "gray",
                            textAlign: "left",
                            marginLeft: "1rem",
                          }}
                        >
                          {`${t(
                            "Introducing our cutting edge mobile PMS app"
                          )}, ${t("manage your property on the go")}!`}
                        </p>
                        <div className="d-flex justify-content-between ml-4">
                          <img
                            style={{
                              height: "4rem",
                              cursor: "pointer",
                              width: "11rem",
                              marginTop: "7px",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              logEventForWidget(
                                eventMap.get("downloadAppAppStore")
                              );
                              window.open(
                                "https://apps.apple.com/us/app/stayflexi-orchestra/id1498345123",
                                "_blank"
                              );
                            }}
                            src={
                              "https://prodimagesbucket.blob.core.windows.net/misc/GPLAYS.png"
                            }
                          />
                          <img
                            style={{
                              height: "73px",
                              width: "12rem",
                              cursor: "pointer",
                              marginTop: "-5px",
                            }}
                            onClick={() => {
                              logEventForWidget(
                                eventMap.get("downloadAppPlayStore")
                              );
                              window.open(
                                "https://play.google.com/store/apps/details?id=com.stayflexi.platform",
                                "_blank"
                              );
                            }}
                            src={
                              "https://prodimagesbucket.blob.core.windows.net/misc/5a902dbf7f96951c82922875.png"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {String(whiteLabelled) === "DISABLED" && (
                    <div
                      className="informationCardWrapper"
                      style={{
                        height: "auto",
                        width: "auto",
                        marginTop: "1rem",
                      }}
                    >
                      <div className="d-flex  flex-column mt-4">
                        {" "}
                        <div
                          style={{
                            color: "black",
                            fontWeight: "500",
                            fontSize: "18px",
                            marginLeft: "1rem",
                          }}
                        >
                          {`${t("View all recommendations")}`}
                        </div>
                        <p
                          className="font-weight-bold mt-2 "
                          style={{
                            color: "gray",
                            textAlign: "left",
                            marginLeft: "1rem",
                          }}
                        >
                          {`${t("Make the most out of Stayflexi")}, ${t(
                            "checkout all the product recommendation"
                          )}`}
                        </p>
                        <div className="d-flex justify-content-center w-100">
                          <Button
                            variant="outlined"
                            className={"supportModalButton"}
                            style={{
                              width: "20rem",
                              marginTop: "1rem",
                              marginBottom: "1rem",
                            }}
                            startIcon={<FeedbackIcon />}
                            onClick={() => {
                              history.push({
                                pathname: "recommendations",
                                search: `?hotelId=${props.data.selectedHotel.hotelId}`,
                              });
                            }}
                          >
                            {`${t("Show me recommendations")}`}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {JSON.parse(
                    props?.data?.selectedHotel?.accessControl?.user_feature_map
                      ?.FlexiGPT
                  ).read === "YES" && (
                    <FlexiGPTModal hotelId={props.data.selectedHotel.hotelId} />
                  )}
                </div>
                <Modal
                  show={directModal}
                  onHide={closeDirectModal}
                  style={{ zIndex: "3000" }}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    {!showDirectPlanData && (
                      <div>
                        <img
                          style={{
                            height: "30px",
                            width: "12rem",
                            cursor: "pointer",
                          }}
                          src={
                            "https://prodimagesbucket.blob.core.windows.net/misc/GHA.png"
                          }
                        />
                        <h4 style={{ marginTop: "1rem" }}>
                          {`${t("Apply")} 7% ${t(
                            "discount on Google hotel ads to get more direct bookings"
                          )}.`}
                        </h4>
                      </div>
                    )}
                    {showDirectPlanData && (
                      <div
                        className="d-flex flex-column w-100"
                        style={{ marginTop: "1rem" }}
                      >
                        <i
                          className="fa fa-check-circle"
                          style={{
                            fontSize: "100px",
                            color: "#1ABBB3",
                            margin: "auto",
                          }}
                        ></i>
                        <h4 style={{ marginTop: "1rem" }}>
                          {`${t("Great")}, ${t(
                            "Stayflexi will coordinate with Google to get more direct conversions"
                          )}`}
                        </h4>
                      </div>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    {!showDirectPlanData && (
                      <Button
                        variant="custom-button"
                        onClick={() => createPromotion()}
                        disabled={genericLoader}
                      >
                        {genericLoader ? (
                          <CircularProgress
                            size={15}
                            color="inherit"
                            sx={{ ml: 1.5 }}
                          />
                        ) : (
                          `${t("Apply discount")}`
                        )}
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={flexModal}
                  onHide={closeFlexModal}
                  style={{ zIndex: "3000" }}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <div
                      className="d-flex flex-column w-100"
                      style={{ marginTop: "1rem" }}
                    >
                      <i
                        className="fa fa-check-circle"
                        style={{
                          fontSize: "100px",
                          color: "#1ABBB3",
                          margin: "auto",
                        }}
                      ></i>

                      <p
                        style={{
                          fontSize: "1.4rem",
                          fontWeight: "200",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "1rem",
                        }}
                      >
                        {`${t("Awesome")}, ${t(
                          "Your flex collect automation is now active"
                        )}. ${t(
                          "if you want to make any changes in configuration click on below button"
                        )}.`}
                      </p>
                      <Button
                        variant="custom-button"
                        onClick={() => {
                          history.push({
                            pathname: "payment-configuration",
                            search: `?hotelId=${props.data.selectedHotel.hotelId}`,
                          });
                        }}
                      >
                        {`${t("Payment configuration")}`}
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>

                <SupportModal
                  show={support}
                  hide={hideSupport}
                  csmData={csmData}
                  currency={props?.data?.selectedHotel?.hotelCurrency}
                ></SupportModal>
                <PaymentModal
                  showPaymentModal={showPaymentModal}
                  paymentAmount={paymentAmount}
                  closePaymentModal={closePaymentModal}
                  days={daysDiff}
                  finalDate={finalDate}
                />
              </>
            )}
          </div>
        ) : (
          <DashboardMockLoader />
        )
      ) : (
        <DashboardMockLoader />
      )}
    </div>
  ) : (
    <DashboardMockLoader />
  );
}

function mapStateToProps(state) {
  return { data: state.auth, hotelType: state.auth.hotelType };
}

export default connect(mapStateToProps, auth.actions)(DashboardPage);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
