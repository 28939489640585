import React, { useState, useEffect, useParams } from "react";
import {
  Stack,
  Typography,
  Modal,
  Box,
  Divider,
  Checkbox,
  Chip,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { SecondaryButton } from "../../../../../Utils/ButtonHelper";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
  BlobProvider,
} from "@react-pdf/renderer";
import { PrintBillDoc } from "./PrintBillDoc";
import moment from "moment";
import $ from "jquery";
import { handleSpringDataRequest } from "../../../../../api/index";
import { connect } from "react-redux";
import {
  getCurrencySymbol,
  getCurrencySymbolForConversion,
} from "../../../../../Utils/Utils";
import axios from "axios";
import Currencies from "../../../../../Utils/Currencies.json";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "fit-content",
  maxHeight: 550,
  pb: 2,
  overflowY: "scroll",
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "50vw",
    backgroundColor: "#fff",
    height: "100%",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

function PrintBillModal({
  openBillFormat,
  // handleOpenBillFormat,
  handleCloseBillFormat,
  menuItems,
  // shopName,
  // shopAddress,
  // shopPhone,
  // orderStatus = "Settled",
  // invoiceNumber,
  // checkComplimentaryOrder = false,
  // taxIdentificationNo,
  // customerName = "",
  // customerPhone = "",
  // customerEmail = "",
  // customerGSTNo = "",
  hotelCurrency,
  // subtotal,
  // tax,
  // discount,
  // total,
  hotelLogo,
  handleUpdateBillAfterPrint,
  orderPage = true,
  // data,
  // orderDate = null,
  orderPunchedInfo = null,
  orderPunchedType = null,
  // serviceCharges,
  // serviceChargeInfo,
  // orderId,
  // shopId,
  balDue,
  // staffId,
  // paxDetails,
  splitPersonId,
  splitBillSpecs,
  // roundOffError,
  order,
}) {
  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");
  /* TYPE OF BILL */
  // Choose the type of bill
  const [A4Bill, setA4Bill] = useState(true);
  const [outletDetails, setOutletDetails] = useState([]);
  // get the specific shop details
  const getShopDetails = () => {
    handleSpringDataRequest(
      `core/api/v1/shop/category/get-all?email=${localStorage.getItem("email")}`
    ).then((res) =>
      res.filter(
        (detail) =>
          String(detail.id) === String(order?.shop_id) &&
          setOutletDetails(detail)
      )
    );
  };

  useEffect(() => {
    if (order) {
      getShopDetails();
    }
  }, [order]);

  $.fn.extend({
    print: function () {
      var frameName = "printIframe";
      var doc = window.frames[frameName];
      if (!doc) {
        $("<iframe>").hide().attr("name", frameName).appendTo(document.body);
        doc = window.frames[frameName];
      }
      doc.document.body.innerHTML = this.html();
      doc.window.print();
      return this;
    },
  });

  const handleBillprint = () => {
    $("#div3").print();
    orderPage && handleUpdateBillAfterPrint();
  };

  // Creating the printable payload (if in case multiple kots of the same item is printed)
  const [printablePayload, setPrintablePayload] = useState([]);
  const createPrintableMenu = () => {
    let parsableItems = menuItems;
    if (splitBillSpecs?.["split_type"] === "ITEM" && splitPersonId) {
      const requiredPayload = JSON.parse(splitBillSpecs?.["split_value"]);
      const payload = menuItems?.filter(
        (item) => requiredPayload[item.id] === splitPersonId
      );
      parsableItems = payload;
    }
    const updatedPayload =
      parsableItems &&
      parsableItems.reduce(function (acc, curr) {
        if (acc.map((item) => item.product_id).includes(curr.product_id)) {
          acc.map(
            (item) =>
              item.product_id === curr.product_id &&
              ((item.product_price += curr.product_price),
              (item.product_qty += curr.product_qty))
          );
        } else {
          acc.push({
            product_id: curr.product_id,
            product_name: curr.product_name,
            product_price: curr.product_price,
            product_qty: curr.product_qty,
          });
        }
        return acc;
      }, []);
    return setPrintablePayload(updatedPayload);
  };

  useEffect(() => {
    createPrintableMenu();
  }, [menuItems]);

  // Handle click after
  const handleClickAfter = () => {
    if (orderPage === true) {
      handleCloseBillFormat();
      handleUpdateBillAfterPrint();
    }
  };

  // Handle KOT View
  const handleKotView = () => {
    if (outletDetails?.shop_name && outletDetails?.shop_name !== undefined) {
      handleBillprint();
      handleCloseBillFormat();
    }
  };

  // Storing the shop id
  // const [sId, setSId] = useState("");
  // useEffect(() => {
  //   setSId(shopId);

  //   return () => {
  //     return setSId("");
  //   };
  // }, [shopId]);

  // Get the payment details
  const [paymentDetails, setPaymentDetails] = useState(null);
  function handleGetPaymentInfo() {
    handleSpringDataRequest(
      `core/api/v1/order-payment/get-op-for-shop-by-orderId?shopId=${order?.shop_id}&orderId=${order?.id}`
    )
      .then((res) => {
        setPaymentDetails(res);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    handleGetPaymentInfo();
  }, []);

  // useEffect(() => {
  //   if (sId) {
  //     handleGetPaymentInfo();
  //   }
  // }, [sId]);

  // Getting the staff name
  const [currentStaffDetails, setCurrentStaffDetails] = useState({
    id: null,
    name: null,
  });
  // const [allStaff, setAllStaff] = useState(null);
  // useEffect(() => {
  //   setCurrentStaffDetails({ id: staffId, name: null });
  // }, [staffId]);

  async function handleGetStaffName() {
    if (order?.order_staff_id) {
      try {
        const res = await handleSpringDataRequest(
          `core/api/v1/shop-staff/get-all?shopId=${order?.shop_id}`
        );
        const currentStaff = res.filter(
          (staff) => String(staff?.id) === String(order?.order_staff_id)
        );
        const staffName = currentStaff[0]?.staff_name;
        setCurrentStaffDetails({ id: order?.order_staff_id, name: staffName });
      } catch (err) {
        console.log("Error in getting shop staffs! Please try again later.");
      }
    }
  }

  useEffect(() => {
    handleGetStaffName();
  }, []);

  // Handle get the qty
  function handleGetVal(qty, type) {
    const updated_qty = Number(qty);
    if (splitPersonId) {
      if (splitBillSpecs?.["split_type"] === "PORTION") {
        const splitAmongst = Number(splitBillSpecs?.["split_value"]);
        return Number(Number(updated_qty / splitAmongst)?.toFixed(2));
      } else if (splitBillSpecs?.["split_type"] === "PERCENTAGE") {
        const requiredPayload = JSON.parse(
          splitBillSpecs?.["split_value"]
        ).filter((item) => item.id === splitPersonId);
        const splitPercent = requiredPayload[0]?.["percentage_value"];
        return Number(
          Number(updated_qty * (Number(splitPercent) / 100))?.toFixed(2)
        );
      } else if (splitBillSpecs?.["split_type"] === "ITEM") {
        const requiredPayload = JSON.parse(splitBillSpecs?.["split_value"]);
        const payload = menuItems?.filter(
          (item) => requiredPayload[item.id] === splitPersonId
        );
        const payload_subtotal = payload?.reduce((acc, curr) => {
          return acc + Number(curr?.product_price);
        }, 0);
        const weight = Number(payload_subtotal) / Number(order?.subtotal);
        const payload_discount = Number(
          (order?.total_order_discount * weight).toFixed(2)
        );

        const payload_tax = payload?.reduce((acc, curr) => {
          return (
            acc +
            ((Number(curr?.product_price) -
              Number(curr?.product_discount) -
              Number(
                (Number(payload_discount) - Number(curr?.product_discount)) /
                  payload.length
              )) *
              Number(curr?.product_tax_perc)) /
              100
          );
        }, 0);

        // const payload_tax = Number((tax * weight).toFixed(2));
        const payload_service_charge = Number(
          (Number(order?.service_charge) * weight)?.toFixed(2)
        );

        const payload_tax_cgst = Number((payload_tax / 2).toFixed(2));
        const payload_tax_sgst = payload_tax_cgst;

        const payload_total = Number(
          Number(
            Number(payload_subtotal) +
              (hotelCurrency === "INR"
                ? Number(payload_tax_cgst) + Number(payload_tax_sgst)
                : Number(payload_tax)) -
              Number(payload_discount) +
              Number(payload_service_charge)
          ).toFixed(2)
        );

        const round_off_err = Number(
          (payload_total - Math.round(payload_total)).toFixed(2)
        );

        switch (type) {
          case "tax":
            return Number(payload_tax.toFixed(2));

          case "tax_cgst":
            return Number(payload_tax_cgst.toFixed(2));

          case "tax_sgst":
            return Number(payload_tax_sgst.toFixed(2));

          case "subtotal":
            return Number(payload_subtotal.toFixed(2));

          case "discount":
            return Number(payload_discount.toFixed(2));

          case "service_charge":
            return Number(payload_service_charge.toFixed(2));

          case "total":
            return Number(payload_total?.toFixed(2));

          case "round_off_total":
            return Math.round(payload_total);

          case "settled_amount":
            return Number(payload_total.toFixed(2));

          case "round_off_err":
            return Number(round_off_err.toFixed(2));

          default:
            return updated_qty;
        }
      }
    } else {
      return updated_qty;
    }
  }

  const [openCurrencyModalDefault, setOpenCurrencyModalDefault] =
    useState(false);
  const [selectedCurrency, setselectedCurrency] = useState(hotelCurrency);
  const [currencyRate, setcurrencyRate] = useState(0);

  useEffect(() => {
    console.log("hotelCurrency", hotelCurrency);
    setselectedCurrency(hotelCurrency);
  }, [hotelCurrency]);

  const handleOpenCurrencyModalDefault = () => {
    if (
      hotelCurrency !== "" &&
      hotelCurrency !== undefined &&
      hotelCurrency !== null
    ) {
      setcurrencyRate(0);
      setselectedCurrency(hotelCurrency);
      getConvertedvalue();
      setOpenCurrencyModalDefault(true);
    }
  };
  const handleCloseCurrencyModalDefault = () => {
    setcurrencyRate(0);
    setselectedCurrency(hotelCurrency);
    setOpenCurrencyModalDefault(false);
  };

  const getConvertedvalue = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_SPRING_API_URL}web-api/currency-convert-value?from=${hotelCurrency}&to=${selectedCurrency}`
    );
    setcurrencyRate(data.data);
  };

  const getUpdatedvalue = async () => {
    const data = await axios.get(
      `${process.env.REACT_APP_SPRING_API_URL}web-api/currency-convert-value?from=${hotelCurrency}&to=${selectedCurrency}`
    );
    setcurrencyRate(data.data);
    setOpenCurrencyModalDefault(false);
  };

  console.log("selectedCurrency", selectedCurrency, currencyRate);

  return (
    <>
      <Modal
        open={openBillFormat}
        onClose={handleCloseBillFormat}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            className="position-relative w-100 justify-content-between p-2"
            direction="row"
          >
            <div className="d-flex">
              <Typography variant="h6" style={{ fontSize: "15px" }}>
                Print bill
              </Typography>
              <CurrencyExchangeIcon
                onClick={() => handleOpenCurrencyModalDefault()}
                sx={{
                  // "&:hover": {
                  //   backgroundColor: "#B5B5C3",
                  //   borderRadius: "50px",
                  // },
                  marginLeft: "1rem",
                  color: "#1BC5BD",
                  cursor: "pointer",
                }}
              ></CurrencyExchangeIcon>
            </div>

            <Typography
              variant="h6"
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => {
                handleCloseBillFormat();
                setA4Bill(false);
              }}
            >
              X
            </Typography>
          </Stack>
          <Divider />
          <Stack className="position-relative w-100 p-4 mt-4 p-4">
            <Typography>
              I want to settle the bill using :{" "}
              <b>{A4Bill === true ? "A4 bill" : "Kot bill view"}</b>
            </Typography>
            <Stack
              className="mt-4 position-relative w-100 justify-content-start mt-4"
              direction="row"
              spacing={2}
            >
              <Chip
                label="A4 bill"
                onClick={() => setA4Bill(true)}
                variant={A4Bill === false && "outlined"}
              />
              <Chip
                label="KOT bill view"
                onClick={() => setA4Bill(false)}
                variant={A4Bill === true && "outlined"}
              />
            </Stack>
          </Stack>
          {[
            order?.cust_name.length,
            order?.cust_email.length,
            order?.cust_phone.length,
          ].includes(0) &&
            A4Bill === true &&
            orderPage === true && (
              <Stack
                className="position-relative mt-1 p-4"
                style={{ color: "red" }}
              >
                Please fill in all the customer details before printing an A4
                bill. Click on Print button to proceed.
              </Stack>
            )}
          <Typography id="div3" style={{ display: "none" }}>
            <div
              id="printableArea"
              className="ticket"
              style={{ width: "100%" }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  display: "flex",
                  // justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ alignItems: "center", justifyContent: "center" }}>
                  <p
                    className="centered"
                    style={{ fontSize: "12.5px", textAlign: "center" }}
                  >
                    <b>{outletDetails?.shop_name}</b> <br />
                    <b>{outletDetails?.shop_address}</b> <br />
                    <b>{outletDetails?.shop_phone}</b>
                  </p>
                </div>

                <div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* {hotelId !== "27768" && ( */}
                    {/* <div style={{ fontSize: "12.5px", marginTop: "5px" }}>
                      Address : {outletDetails?.shop_address}
                    </div> */}
                    {/* )} */}
                    {/* {hotelId !== "27768" && ( */}
                    {/* <div style={{ fontSize: "12.5px", marginTop: "5px" }}>
                      Phone No. : {outletDetails?.shop_phone}
                    </div> */}
                    {/* )} */}
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        // flexDirection: hotelId !== "27768" ? "column" : "row",
                        // justifyContent: hotelId === "27768" && "space-between",
                      }}
                    >
                      <div>
                        {order?.complimentary_order === "NO" &&
                          order?.order_status &&
                          ["printed", "running", "inroom", "settled"].includes(
                            order?.order_status?.toLowerCase()
                          ) && (
                            <div style={{ fontSize: "12.5px" }}>
                              Bill no. : {order?.invoice_no}
                              {/* {invoiceNumber} */}
                            </div>
                          )}
                        {order?.order_status?.toLowerCase() === "cancelled" &&
                          order?.order_status && (
                            <div style={{ fontSize: "12.5px" }}>
                              Bill no. : (Cancelled) {order?.invoice_no}
                              {/* {invoiceNumber} */}
                            </div>
                          )}
                        {order?.complimentary_order === "YES" &&
                          [
                            "printed",
                            "running",
                            "inroom",
                            "settled",
                            "complimentary",
                          ].includes(order?.order_status?.toLowerCase()) &&
                          order?.order_status && (
                            <div
                              style={{
                                // marginBottom: 5,
                                fontSize: "12.5px",
                              }}
                            >
                              Bill no. : (Complimentary) {order?.invoice_no}
                              {/* {invoiceNumber} */}
                            </div>
                          )}
                      </div>
                      <div
                        style={{
                          // marginTop: "5px",
                          fontSize: "12.5px",
                          marginLeft: "5px",
                        }}
                      >
                        Order date :{" "}
                        {order?.order_date
                          ? moment(order?.order_date).format("MMM DD 'YY")
                          : moment().format("MMM DD 'YY")}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        // flexDirection: hotelId === "27768" ? "row" : "column",
                        // justifyContent: hotelId === "27768" && "space-between",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        marginTop: "5px",
                      }}
                    >
                      <div style={{ fontSize: "12.5px" }}>
                        {orderPunchedInfo &&
                          orderPunchedType &&
                          `${orderPunchedInfo} - ${orderPunchedType}`}
                      </div>
                      {order?.no_of_persons && (
                        <div style={{ marginLeft: "5px", fontSize: "12.5px" }}>
                          PAX : {order?.no_of_persons}
                          {/* {paxDetails} */}
                        </div>
                      )}
                    </div>
                    {currentStaffDetails.name && hotelId !== "27768" && (
                      <div style={{ marginTop: "5px", fontSize: "12.5px" }}>
                        Staff : {currentStaffDetails.name}
                      </div>
                    )}
                    {![undefined, null, "N/A"].includes(
                      outletDetails?.tax_identification_number
                    ) &&
                      hotelId !== "27768" && (
                        <div style={{ marginTop: "5px", fontSize: "12.5px" }}>
                          {["Rs.", "Rs", "INR", "inr"].includes(
                            String(hotelCurrency)
                          )
                            ? "Outlet GST NO. : "
                            : "Outlet Tax NO. : "}{" "}
                          {outletDetails?.tax_identification_number}
                        </div>
                      )}
                  </div>
                  {/* <div
                    style={{
                      position: "relative",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    {order?.cust_name?.length > 0 && (
                      <div style={{ marginTop: "5px", fontSize: "12.5px" }}>
                        {order?.cust_name}
                      </div>
                    )}
                    {order?.cust_phone?.length > 0 && (
                      <div style={{ marginTop: "5px", fontSize: "12.5px" }}>
                        {order?.cust_phone}
                      </div>
                    )}
                    {order?.cust_email?.length > 0 && (
                      <div style={{ marginTop: "5px", fontSize: "12.5px" }}>
                        {order?.cust_email}
                      </div>
                    )}
                    {order?.cust_gst_no?.length > 0 && (
                      <div style={{ marginTop: "5px", fontSize: "12.5px" }}>
                        {order?.cust_gst_no}
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
              <hr />
              <table
                style={{
                  position: "relative",
                  width: "100%",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <thead>
                  <tr>
                    {hotelId !== "27768" && (
                      <th className="sno" style={{ fontSize: "12.5px" }}>
                        S.No.
                      </th>
                    )}
                    <th className="item" style={{ fontSize: "12.5px" }}>
                      Item
                    </th>
                    <th className="qty" style={{ fontSize: "12.5px" }}>
                      Qty.
                    </th>
                    <th className="price" style={{ fontSize: "12.5px" }}>
                      Price (
                      {getCurrencySymbolForConversion(
                        hotelCurrency !== selectedCurrency
                          ? selectedCurrency
                          : hotelCurrency
                      )}
                      )
                    </th>
                    <th className="price" style={{ fontSize: "12.5px" }}>
                      Total (
                      {getCurrencySymbolForConversion(
                        hotelCurrency !== selectedCurrency
                          ? selectedCurrency
                          : hotelCurrency
                      )}
                      )
                    </th>
                  </tr>
                </thead>
                <tbody align="center">
                  {printablePayload
                    ?.filter((items) => Number(items?.id) !== -1)
                    .map((item, index) => (
                      <tr>
                        {hotelId !== "27768" && (
                          <td
                            className="sno"
                            style={{
                              position: "relative",
                              width: "5%",
                              fontSize: "12px",
                            }}
                          >
                            {index + 1}
                          </td>
                        )}
                        <td
                          className="item"
                          style={{
                            position: "relative",
                            width: "27%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        >
                          {item.product_name}
                        </td>
                        <td
                          className="qty"
                          style={{
                            position: "relative",
                            width: "18%",
                            alignItems: "center",
                            fontSize: "12px",
                          }}
                        >
                          {handleGetVal(item.product_qty, "qty")}
                        </td>
                        <td
                          className="price"
                          style={{
                            position: "relative",
                            width: "24%",
                            alignItems: "center",
                            fontSize: "12px",
                          }}
                        >
                          {hotelCurrency === selectedCurrency
                            ? parseFloat(
                                Number(item.product_price) /
                                  Number(item.product_qty)
                              ).toFixed(2)
                            : parseFloat(
                                (Number(item.product_price) * currencyRate) /
                                  Number(item.product_qty)
                              ).toFixed(2)}
                        </td>
                        <td
                          className="price"
                          style={{
                            position: "relative",
                            width: "24%",
                            alignItems: "center",
                            fontSize: "12px",
                          }}
                        >
                          {hotelCurrency === selectedCurrency
                            ? handleGetVal(
                                parseFloat(item.product_price, "price").toFixed(
                                  2
                                )
                              )
                            : handleGetVal(
                                parseFloat(
                                  item.product_price * currencyRate,
                                  "price"
                                ).toFixed(2)
                              )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <hr />
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <table>
                  <tbody>
                    <tr>
                      <th className="total" style={{ fontSize: "12.5px" }}>
                        Subtotal{" "}
                      </th>
                      <td className="total" style={{ fontSize: "12.5px" }}>
                        {getCurrencySymbolForConversion(
                          hotelCurrency !== selectedCurrency
                            ? selectedCurrency
                            : hotelCurrency
                        )}
                        {handleGetVal(
                          hotelCurrency === selectedCurrency
                            ? order?.subtotal
                            : parseFloat(
                                order?.subtotal * currencyRate
                              ).toFixed(2),
                          "subtotal"
                        )}
                      </td>
                    </tr>
                    {order?.complimentary_order === "NO" &&
                      order?.order_status?.toLowerCase() !== "cancelled" &&
                      hotelId !== "27768" && (
                        <tr>
                          <th className="total" style={{ fontSize: "12.5px" }}>
                            Discount{" "}
                          </th>
                          <td className="total" style={{ fontSize: "12.5px" }}>
                            {getCurrencySymbolForConversion(
                              hotelCurrency !== selectedCurrency
                                ? selectedCurrency
                                : hotelCurrency
                            )}
                            {handleGetVal(
                              hotelCurrency === selectedCurrency
                                ? order?.total_order_discount
                                : parseFloat(
                                    order?.total_order_discount * currencyRate
                                  ).toFixed(2),

                              "discount"
                            )}
                          </td>
                        </tr>
                      )}
                    {hotelCurrency === "INR" ? (
                      <>
                        {hotelId !== "27768" && (
                          <tr>
                            <th
                              className="total"
                              style={{ fontSize: "12.5px" }}
                            >
                              Total tax
                            </th>
                            <td
                              className="total"
                              style={{ fontSize: "12.5px" }}
                            >
                              {getCurrencySymbolForConversion(
                                hotelCurrency !== selectedCurrency
                                  ? selectedCurrency
                                  : hotelCurrency
                              )}
                              {handleGetVal(
                                parseFloat(
                                  hotelCurrency === selectedCurrency
                                    ? order?.tax_percentage
                                    : order?.tax_percentage * currencyRate
                                ).toFixed(2),

                                "tax"
                              )}
                            </td>
                          </tr>
                        )}
                        {hotelId !== "27768" && (
                          <tr>
                            <th
                              className="total"
                              style={{ fontSize: "12.5px" }}
                            >
                              CGST
                            </th>
                            <td
                              className="total"
                              style={{ fontSize: "12.5px" }}
                            >
                              {getCurrencySymbolForConversion(
                                hotelCurrency !== selectedCurrency
                                  ? selectedCurrency
                                  : hotelCurrency
                              )}
                              {Number(
                                handleGetVal(
                                  Number(
                                    hotelCurrency === selectedCurrency
                                      ? order?.tax_percentage
                                      : order?.tax_percentage * currencyRate
                                  ) / 2,
                                  "tax_cgst"
                                )
                              ).toFixed(2)}
                            </td>
                          </tr>
                        )}
                        {hotelId !== "27768" && (
                          <tr>
                            <th
                              className="total"
                              style={{ fontSize: "12.5px" }}
                            >
                              {localStorage.getItem("utgstStatus") === "ENABLED"
                                ? "UTGST"
                                : "SGST"}
                            </th>
                            <td
                              className="total"
                              style={{ fontSize: "12.5px" }}
                            >
                              {getCurrencySymbolForConversion(
                                hotelCurrency !== selectedCurrency
                                  ? selectedCurrency
                                  : hotelCurrency
                              )}
                              {Number(
                                handleGetVal(
                                  Number(
                                    hotelCurrency === selectedCurrency
                                      ? order?.tax_percentage
                                      : order?.tax_percentage * currencyRate
                                  ) / 2,
                                  "tax_sgst"
                                )
                              ).toFixed(2)}
                            </td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <th className="total" style={{ fontSize: "12.5px" }}>
                          Tax{" "}
                        </th>
                        <td className="total" style={{ fontSize: "12.5px" }}>
                          {getCurrencySymbolForConversion(
                            hotelCurrency !== selectedCurrency
                              ? selectedCurrency
                              : hotelCurrency
                          )}
                          {handleGetVal(
                            hotelCurrency === selectedCurrency
                              ? order?.tax_percentage
                              : order?.tax_percentage * currencyRate,
                            "tax"
                          )}
                        </td>
                      </tr>
                    )}
                    {Number(order?.service_charge) !== 0 &&
                      order?.apply_service_charge_bydefault === "YES" &&
                      order?.complimentary_order === "NO" &&
                      order?.order_status?.toLowerCase() !== "cancelled" && (
                        <tr>
                          <th className="total" style={{ fontSize: "12.5px" }}>
                            Service charges{" "}
                            {outletDetails?.service_charge_type ===
                              "PERCENTAGE" &&
                              `@${outletDetails?.service_charge_value}`}
                          </th>
                          <td className="total" style={{ fontSize: "12.5px" }}>
                            {getCurrencySymbolForConversion(
                              hotelCurrency !== selectedCurrency
                                ? selectedCurrency
                                : hotelCurrency
                            )}
                            {handleGetVal(
                              hotelCurrency === selectedCurrency
                                ? order?.service_charge
                                : order?.service_charge * currencyRate,

                              "service_charge"
                            )}
                          </td>
                        </tr>
                      )}
                    <tr>
                      <th className="total" style={{ fontSize: "12.5px" }}>
                        {hotelCurrency === "INR" &&
                        order?.round_off_amount !== 0
                          ? "Total"
                          : "Grand total"}
                      </th>
                      <td className="total" style={{ fontSize: "12.5px" }}>
                        {getCurrencySymbolForConversion(
                          hotelCurrency !== selectedCurrency
                            ? selectedCurrency
                            : hotelCurrency
                        )}
                        {order?.complimentary_order === "YES"
                          ? handleGetVal(
                              hotelCurrency === selectedCurrency
                                ? order?.subtotal
                                : order?.subtotal * currencyRate,
                              "subtotal"
                            )
                          : (
                              handleGetVal(
                                hotelCurrency === selectedCurrency
                                  ? order?.order_amount
                                  : order?.order_amount * currencyRate,

                                "total"
                              ) +
                              handleGetVal(
                                hotelCurrency === selectedCurrency
                                  ? order?.round_off_amount
                                  : order?.round_off_amount * currencyRate,

                                "round_off_err"
                              )
                            ).toFixed(2)}
                      </td>
                    </tr>

                    {hotelCurrency === "INR" &&
                      order?.round_off_amount !== 0 && (
                        <tr>
                          <th className="total" style={{ fontSize: "12.5px" }}>
                            Grand total
                          </th>
                          <td className="total" style={{ fontSize: "12.5px" }}>
                            {getCurrencySymbolForConversion(
                              hotelCurrency !== selectedCurrency
                                ? selectedCurrency
                                : hotelCurrency
                            )}
                            {order?.complimentary_order === "YES"
                              ? handleGetVal(
                                  hotelCurrency === selectedCurrency
                                    ? order?.subtotal
                                    : order?.subtotal * currencyRate,
                                  "subtotal"
                                )
                              : Math.round(
                                  Number(
                                    handleGetVal(
                                      hotelCurrency === selectedCurrency
                                        ? order?.order_amount
                                        : order?.order_amount * currencyRate,
                                      "total"
                                    )
                                  )
                                )}
                          </td>
                        </tr>
                      )}
                    {hotelCurrency === "INR" &&
                      order?.round_off_amount !== 0 && (
                        <tr>
                          <th className="total" style={{ fontSize: "12.5px" }}>
                            Round off
                          </th>
                          <td className="total" style={{ fontSize: "12.5px" }}>
                            {getCurrencySymbolForConversion(
                              hotelCurrency !== selectedCurrency
                                ? selectedCurrency
                                : hotelCurrency
                            )}
                            {splitPersonId
                              ? (
                                  Math.round(
                                    Number(
                                      handleGetVal(
                                        hotelCurrency === selectedCurrency
                                          ? order?.order_amount
                                          : order?.order_amount * currencyRate,
                                        "total"
                                      )
                                    )
                                  ) -
                                  (handleGetVal(
                                    hotelCurrency === selectedCurrency
                                      ? order?.order_amount
                                      : order?.order_amount * currencyRate,
                                    "total"
                                  ) +
                                    handleGetVal(
                                      hotelCurrency === selectedCurrency
                                        ? order?.round_off_amount
                                        : order?.round_off_amount *
                                            currencyRate,
                                      "round_off_err"
                                    ))
                                ).toFixed(2)
                              : Number(
                                  handleGetVal(
                                    hotelCurrency === selectedCurrency
                                      ? order?.round_off_amount
                                      : parseFloat(
                                          order?.round_off_amount * currencyRate
                                        ).toFixed(2),
                                    "round_off_err"
                                  )
                                )}
                          </td>
                        </tr>
                      )}
                    {splitPersonId ? (
                      <tr>
                        <th className="total" style={{ fontSize: "12.5px" }}>
                          Paid
                        </th>
                        <td className="total" style={{ fontSize: "12.5px" }}>
                          {getCurrencySymbolForConversion(
                            hotelCurrency !== selectedCurrency
                              ? selectedCurrency
                              : hotelCurrency
                          )}
                          {Math.round(
                            handleGetVal(
                              hotelCurrency === selectedCurrency
                                ? order?.order_amount
                                : parseFloat(
                                    order?.order_amount * currencyRate
                                  ).toFixed(2),

                              "total"
                            )
                          )}
                        </td>
                      </tr>
                    ) : (
                      paymentDetails
                        // ?.filter(
                        //   (orderSpecificPayment) =>
                        //     String(orderSpecificPayment?.order_id) ===
                        //     String(order?.id)
                        // )
                        ?.map(
                          (payment) =>
                            Number(payment?.settled_amount) > 0 && (
                              <tr>
                                <th
                                  className="total"
                                  style={{ fontSize: "12.5px" }}
                                >
                                  Paid via {payment?.payment_type}
                                </th>
                                <td
                                  className="total"
                                  style={{ fontSize: "12.5px" }}
                                >
                                  {selectedCurrency}{" "}
                                  {handleGetVal(
                                    hotelCurrency === selectedCurrency
                                      ? payment?.settled_amount
                                      : parseFloat(
                                          payment?.settled_amount * currencyRate
                                        ).toFixed(2),

                                    "settled_amount"
                                  )}
                                </td>
                              </tr>
                            )
                        )
                    )}
                    {Number(balDue) > 0 &&
                      order?.complimentary_order === "NO" &&
                      order?.order_status?.toLowerCase() !== "cancelled" && (
                        <tr>
                          <th className="total" style={{ fontSize: "12.5px" }}>
                            Balance due
                          </th>
                          <td className="total" style={{ fontSize: "12.5px" }}>
                            {getCurrencySymbolForConversion(
                              hotelCurrency !== selectedCurrency
                                ? selectedCurrency
                                : hotelCurrency
                            )}
                            {hotelCurrency === "INR" &&
                            order?.round_off_amount !== 0
                              ? Math.round(
                                  handleGetVal(
                                    hotelCurrency === selectedCurrency
                                      ? Number(balDue)
                                      : Number(balDue) * currencyRate,
                                    "bal_due"
                                  )
                                )
                              : handleGetVal(
                                  hotelCurrency === selectedCurrency
                                    ? Number(balDue)
                                    : Number(balDue) * currencyRate,
                                  "bal_due"
                                )}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              <hr />
              {hotelId !== "27768" && (
                <p style={{ marginTop: "25px", fontSize: "12.5px" }}>
                  Guest signature :{" "}
                </p>
              )}
              {hotelId !== "27768" && (
                <p
                  style={{
                    position: "absolute",
                    margin: "auto",
                    marginTop: "15px",
                    fontSize: "12.5px",
                    color: "grey",
                  }}
                >
                  Thanks for visiting{" "}
                  <b style={{ color: "grey" }}> {outletDetails?.shop_name}</b>
                </p>
              )}
            </div>
          </Typography>
          <Stack
            className="mt-4 p-4 position-relative w-100 justify-content-between"
            direction="row"
          >
            <SecondaryButton
              text="Cancel"
              onClick={() => {
                handleCloseBillFormat();
                setA4Bill(false);
              }}
            />
            {A4Bill === false ? (
              outletDetails && paymentDetails ? (
                <Button variant="custom-button" onClick={() => handleKotView()}>
                  Print KOT bill view
                </Button>
              ) : (
                <Button variant="custom-button">Loading...</Button>
              )
            ) : paymentDetails ? (
              <PDFDownloadLink
                document={
                  <Document title={""}>
                    <Page size="A4" style={styles.page} wrap>
                      <PrintBillDoc
                        menuItems={printablePayload}
                        // shopName={shopName}
                        // shopAddress={shopAddress}
                        // shopPhone={shopPhone}
                        // taxIdentificationNo={taxIdentificationNo}
                        // customerName={order?.cust_name}
                        // customerPhone={order?.cust_phone}
                        // customerEmail={order?.cust_email}
                        // customerGSTNo={order?.cust_gst_no}
                        symbol={selectedCurrency}
                        // subtotal={subtotal}
                        // tax={tax}
                        // discount={discount}
                        // total={total}
                        hotelLogo={hotelLogo}
                        // orderStatus={orderStatus && orderStatus}
                        // invoiceNumber={invoiceNumber}
                        // checkComplimentaryOrder={checkComplimentaryOrder}
                        // orderDate={orderDate}
                        orderPunchedInfo={orderPunchedInfo}
                        orderPunchedType={orderPunchedType}
                        // serviceCharges={serviceCharges}
                        // serviceChargeInfo={serviceChargeInfo}
                        // orderId={orderId}
                        // shopId={shopId}
                        paymentDetails={paymentDetails && paymentDetails}
                        balDue={balDue}
                        currentStaffDetails={currentStaffDetails}
                        // paxDetails={paxDetails}
                        handleGetVal={handleGetVal}
                        splitPersonId={splitPersonId}
                        // roundOffError={roundOffError}
                        // Adding new values
                        order={order}
                        outletDetails={outletDetails}
                        isConverted={hotelCurrency !== selectedCurrency}
                        currencyRate={currencyRate}
                      />
                    </Page>
                  </Document>
                }
                fileName={`${outletDetails?.shop_name}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading && !paymentDetails ? (
                    <Button variant="custom-button">Loading bill...</Button>
                  ) : (
                    <a href={url} target="_blank">
                      <Button
                        variant="custom-button"
                        onClick={() => handleClickAfter()}
                      >
                        Print A4 bill
                      </Button>
                    </a>
                  )
                }
              </PDFDownloadLink>
            ) : (
              <Button variant="custom-button" disabled={true}>
                Loading...
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openCurrencyModalDefault}
        onClose={handleCloseCurrencyModalDefault}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="foliosConfirmationModal">
          <div className="folioConfirmationWrapper">
            <div className="folioConfContent">
              <h4>{"Convert currency"}</h4>
              <p className="text-muted">
                {
                  "For manual price calculation, kindly edit the price in the amount field"
                }
              </p>
              <div className="d-flex align-items-center justify-content-between">
                <TextField
                  id="outlined-name"
                  label={"Hotel Currency"}
                  name="Hotel Currency"
                  disabled={true}
                  value={hotelCurrency}
                  // onChange={(e) => setcurrencyRate(e.target.value)}
                  sx={{ width: "8rem", marginRight: "2rem" }}
                  type={"text"}
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <FormControl
                  fullWidth
                  style={{ width: "12rem", marginRight: "2rem" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Convert currency
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setselectedCurrency(e.target.value);
                      getConvertedvalue();
                    }}
                    style={{
                      marginLeft: "0rem",
                      width: "12rem",
                      marginRight: "2rem",
                    }}
                    className="mr-4"
                    label={"Convert currency"}
                    name="Convert currency"
                    value={selectedCurrency}
                  >
                    {Currencies?.map((hours) => (
                      <MenuItem value={hours.code}>{hours.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-name"
                  label={`${"Amount"} ${selectedCurrency}`}
                  name={`Amount ${selectedCurrency}`}
                  value={currencyRate}
                  onChange={(e) => setcurrencyRate(e.target.value)}
                  sx={{ width: "8rem" }}
                  type={"text"}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </div>
              {/* <Button variant="custom-button" onClick={fetchRates}>
                  Fetch Currency rates
                </Button> */}
            </div>
            <div className="folioConfButtons">
              <button
                className="cancelBtn"
                onClick={() => handleCloseCurrencyModalDefault()}
              >
                {"Cancel"}
              </button>
              <Button
                className="submit"
                variant="custom-button"
                onClick={() => getUpdatedvalue()}
              >
                {"Fetch rates"}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(PrintBillModal);
